import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Grid, Typography, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import CardTitle from './shared/CardTitle'
import { makeStyles } from '@material-ui/core/styles'
import { renderTable } from '../components/shared/TableHelper'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { renderMarkers, getLatLng } from '../components/shared/MapHelper'
import { retrieveView } from './shared/Views'
import { Link, Button } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    }
}))

const milestones = [
    { key: 'description', label: 'Description', icon: true },
    { key: 'estimatedDate', label: 'Estimate', icon: false },
    { key: 'actualDate', label: 'Actual', icon: false }
]

const packedItems = [
    { key: 'productCode', label: 'Product Code', icon: false },
    { key: 'productDescription', label: 'Product Description', icon: false },
    { key: 'packedQuantity', label: 'Packed Qty', icon: false },
    { key: 'quantityUOM', label: 'UOM', icon: false }
]

export default function DialogContainer(props) {
    const [dialogContainerHeaders, setDialogContainerHeaders] = useState([]) 
    const { container, dialogCallback, organization, client, showDialog } = props
    const classes = useStyles()

    useEffect(() => {
        getContainerDialogContainerHeaders()
    }, [])
    
    const getContainerDialogContainerHeaders = async () => {
        const view = await retrieveView(null, 'ShipmentContainerDetails')
        if (view) {
            let containerHeaders = view.columnDefinitionsArray
            // need to map value and title properties to key and label and add icon and link properties
            containerHeaders.forEach(header => {
                header.key = header.value
                header.label = header.title
                header.icon = false
                header.link = false
            })
            // filter out headers where visible property is false
            containerHeaders = containerHeaders.filter(header => header.visible)
            // remove containerTrackingLink column if organization does not have containerTrackingURL
            if (!organization?.containerTrackingURL) {
                containerHeaders = containerHeaders.filter(
                    c => c.key !== 'containerTrackingLink'
                )
            }
            // set link true if key is containerTrackingLink
            containerHeaders.forEach(header => {
                if (header.key === 'containerTrackingLink') {
                    header.link = true
                }
            })
            setDialogContainerHeaders(containerHeaders)
        }
    }

    if (!showDialog) return null

    const renderContainerLink = (i, index, arrayKey) => {
        if (
            arrayKey.key === 'containerTrackingLink' &&
            organization.containerTrackingURL
        ) {
            return (
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        const newWindow = window.open(
                            organization.containerTrackingURL.replace(
                                '{CONTAINERNO}',
                                i['containerNumber']
                            ),
                            '_blank',
                            'noopener,noreferrer'
                        )
                        if (newWindow) newWindow.opener = null
                    }}>
                    View
                </Button>
            )
        }

        if (arrayKey.key === 'openTrackLink' && (client.isOpenTrack || organization.isOpenTrack)) {
            return (
                // check if value is null 
                i[arrayKey.key] !== null && i[arrayKey.key] !== '' ? (
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            const newWindow = window.open(
                                i[arrayKey.key],
                                '_blank',
                                'noopener,noreferrer'
                            )
                            if (newWindow) newWindow.opener = null
                        }}>
                        View
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled>
                        View
                    </Button>
                )
            )
        }
    }

    const renderPackedItems = container => {
        return (
            <CardTitle
                title="Packed Items"
                checkOn={false}
                disablePadding={true}>
                    {renderTable(
                        container.packedItems,
                        packedItems,
                        'packedItems',
                        classes,
                        null,
                        {
                            maxHeight: '350px'
                        }
                    )}
            </CardTitle>
        )
    }

    const renderMilestones = container => {
        return (
            <CardTitle title="Milestones" checkOn={false} disablePadding={true}>
                {renderTable(
                    container.milestones,
                    milestones,
                    'milestones',
                    classes,
                    null,
                    {
                        maxHeight: '350px'
                    }
                )}
            </CardTitle>
        )
    }

    const renderDetails = container => {
        return (
            <CardTitle title="Details" checkOn={false} disablePadding={true}>
                {renderTable(
                    [container],
                    dialogContainerHeaders,
                    'Details',
                    classes,
                    renderContainerLink,
                    {
                        maxHeight: '200px'
                    }
                )}
            </CardTitle>
        )
    }

    const getMapBounds = (container) => {
        const bounds = new window.google.maps.LatLngBounds()
        const originCenter = getLatLng(
            container,
            'geoLocationOrigin'
        )
        const destinationCenter = getLatLng(
            container,
            'geoLocationDestination'
        )
        if (bounds && originCenter && destinationCenter) { 
            bounds.extend(
                new window.google.maps.LatLng(
                    originCenter.lat,
                    originCenter.lng
                )
            )
            bounds.extend(
                new window.google.maps.LatLng(
                    destinationCenter.lat,
                    destinationCenter.lng
                )
            )
        }
        return bounds
    }

    const renderMarker = container => {
        const originCenter = getLatLng(container, 'geoLocationOrigin')
        const destinationCenter = getLatLng(container, 'geoLocationDestination')
        const portOfLoadingCenter = getLatLng(
            container,
            'geoLocationPortOfLoading'
        )
        const portOfDischargeCenter = getLatLng(
            container,
            'geoLocationPortOfDischarge'
        )
        const currentLocationCenter = getLatLng(
            container,
            'geoLocationCurrentLocation'
        )

        return renderMarkers(
            container.containerNumber,
            container,
            null,
            originCenter,
            destinationCenter,
            portOfLoadingCenter,
            portOfDischargeCenter,
            currentLocationCenter
        )
    }


    return (
        <Dialog
            open={showDialog}
            fullScreen
            className="px-5 py-3"
            onClose={() => dialogCallback(false)}>
            <DialogTitle className={classes.title} disableTypography>
                <div className="row text-left p-0">
                    <IconButton onClick={() => dialogCallback(false)}>
                        <Close color="secondary" />
                    </IconButton>
                </div>
                <div className="row text-center">
                    <Typography variant="h6" className="w-100">
                        {' '}
                        Container Details | {container?.containerNumber}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="row">
                            <div className="col-12">
                            {!window.google ? (
                                <LoadScript
                                id="script-loader"
                                googleMapsApiKey="AIzaSyChJdyVnqwtrE_-7mUBGQNRUwa3oJFrJsE">
                                <GoogleMap
                                    id="infobox-container"
                                    mapContainerStyle={{
                                        width: '850x',
                                        height: '400px'
                                    }}
                                    zoom={2}
                                    options={{ streetViewControl: false }}
                                    onLoad={map => {
                                        const bounds = getMapBounds(container)
                                        if (bounds) {
                                            map.fitBounds(bounds)
                                        }
                                    }}>
                                    {renderMarker(container)}
                                </GoogleMap>
                            </LoadScript>) : (<GoogleMap
                                    id="infobox-container"
                                    mapContainerStyle={{
                                        width: '850x',
                                        height: '400px'
                                    }}
                                    zoom={2}
                                    options={{ streetViewControl: false }}
                                    onLoad={map => {
                                        const bounds = getMapBounds(container)
                                        if (bounds) {
                                            map.fitBounds(bounds)
                                        }
                                    }}>
                                    {renderMarker(container)}
                                </GoogleMap>)}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {renderDetails(container)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderMilestones(container)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderPackedItems(container)}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}