import React, { useState, useEffect } from 'react'
import authService from './api-authorization/AuthorizeService'
import { makeStyles } from '@material-ui/core/styles'
import { CardContent, Grid } from '@material-ui/core'
import {
    FormControl,
    TextField,
    Button,
    List,
    ListItem
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Input, InputLabel } from '@material-ui/core'
import { MenuItem, Select, Typography } from '@material-ui/core'
import AlertMessage from './shared/Alert'
import voca, { search } from 'voca'
import { useForm, Controller } from 'react-hook-form'
import _, { set } from 'lodash'
import { CustomDialog } from './shared/CustomDialog'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import SearchIcon from '@material-ui/icons/Search'
import Paper from '@material-ui/core/Paper'
import ShipmentCreationProgress from './CreateShipment/ShipmentCreationProgress'
import DateFnsUtils from '@date-io/moment'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'
import { getDataMapping, setDataMapping } from '../utils/data-mapping'
import { getCreatedShipment, getUNLOCO, getLocationByUNLOCO } from '../api/api'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Icon from '@material-ui/core/Icon'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { LinearProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getOrgNameFromOrganization } from '../utils/organizations'
import { renderTable } from '../components/shared/TableHelper'
import { retrieveView } from './shared/Views'
import { getNextEnabledBookingStep } from './CreateShipment/CreateBookingFunctions'

const classes = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    },
    heading: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
}))

const dateFns = new DateFnsUtils()

export default function CreateShipmentForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        formState,
        setValue,
        getValues,
        control,
        watch
    } = useForm()
    const { isDirty } = formState

    const [organization, setOrganization] = useState([])
    const [clients, setClients] = useState([])
    const [currentClient, setCurrentClient] = useState(null)
    const [transportModes, setTransportModes] = useState([])
    const [incoTerms, setIncoTerms] = useState([])
    const [commodities, setCommodities] = useState([])
    const [containerModes, setContainerModes] = useState([])
    const [serviceLevels, setServiceLevels] = useState([])
    const [pieceUnits, setPieceUnits] = useState([])
    const [weightUnits, setWeightUnits] = useState([])
    const [volumeUnits, setVolumeUnits] = useState([])
    const [addresses, setAddresses] = useState([])
    const [filteredAddresses, setFilteredAddresses] = useState([])
    const [orgConsigneeAddresses, setOrgConsigneeAddresses] = useState([])
    const [filteredOrgConsigneeAddresses, setFilteredOrgConsigneeAddresses] = useState([])
    const [orgConsignorAddresses, setOrgConsignorAddresses] = useState([])
    const [filteredOrgConsignorAddresses, setFilteredOrgConsignorAddresses] = useState([])
    const [isConsignor, setIsConsignor] = useState(true)
    const [originUnlocoCodes, setOriginUnlocoCodes] = useState([])
    const [destinationUnlocoCodes, setDestinationUnlocoCodes] = useState([])
    const [filteredContainerModes, setFilteredContainerModes] = useState([])
    const [didSetOriginUnlocoCode, setDidSetOriginUnlocoCode] = useState(false)
    const [didSetDestinationUnlocoCode, setDidSetDestinationUnlocoCode] = useState(false)
    const [originUNLOCOCodeObject, setOriginUNLOCOCodeObject] = useState(null)
    const [destinationUNLOCOCodeObject, setDestinationUNLOCOCodeObject] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [consignorAddressesModalOpen, setConsignorAddressesModalOpen] = useState(false)
    const [consigneeAddressesModalOpen, setConsigneeAddressesModalOpen] = useState(false)
    const [key, setKey] = useState(['shipment.create'])
    const [organizationId, setOrganizationId] = useState(null)
    const [bookingAlert, setBookingAlert] = useState({
        open: false,
        success: false,
        message: ''
    })
    const [orderAlert, setOrderAlert] = useState({
        open: false,
        success: false,
        message: ''
    })
    const [referredOrderAlert, setReferredOrderAlert] = useState({
        open: false,
        success: false,
        message: ''
    })
    const [colorThemePrimaryRGB, setcolorThemePrimaryRGB] = useState('')
    const [colorThemeSecondaryRGB, setcolorThemeSecondaryRGB] = useState('')
    const [colorThemeTertiaryRGB, setcolorThemeTertiaryRGB] = useState('')
    const [colorThemeRGB, setcolorThemeRGB] = useState('')
    const [colorThemeTextRGB, setcolorThemeTextRGB] = useState('')
    const [retrievingOrder, setRetrievingOrder] = useState(false)
    const [retrievingDataReferred, setretrievingDataReferred] = useState(false)
    const [expandedPanels, setExpandedPanels] = useState(['panel1'])
    const [bookingOrders, setBookingOrders] = useState([])
    const [linkedOrderHeaderColumns, setLinkedOrderHeaderColumns] = useState([])
    const [pageTitle, setPageTitle] = useState('Create Booking')
    const [shipmentNumber, setShipmentNumber] = useState(null)
    const [editCopyBookingNumber, setEditCopyBookingNumber] = useState(null)
    const [retrievingCW1Booking, setRetrievingCW1Booking] = useState(false)
    const [consolidatedOrderList, setConsolidatedOrderList] = useState([])
    const [controllingCustomerCode, setControllingCustomerCode] = useState(null)
    const [selectedAddressForeignKey, setselectedAddressForeignKey] = useState(null)
    const watchTransportMode = watch('transportModeCode')

    useEffect(() => {
        getClients()
        setLinkedOrderColumnDefinitions()
    }, [])

    useEffect(() => {

        let shipmentNumber = sessionStorage.getItem(shipmentIdKey())
        const editCopyBookingNumber = localStorage.getItem('editCopyBookingNumber')
        const editCopyBookingClientId = localStorage.getItem('editCopyBookingClientId')

        if (editCopyBookingNumber) {
            setRetrievingCW1Booking(true)
        }

        if (shipmentNumber || editCopyBookingNumber) {
          
            const client = clients.find(c => c.id === editCopyBookingClientId)
            // need to clear shipmentnumber if there is an edit copy booking number
            if (editCopyBookingNumber) {
                shipmentNumber = null
            }

            if (client || shipmentNumber) {

                getCreatedShipment(shipmentNumber, editCopyBookingClientId, editCopyBookingNumber, async data => {

                    if (editCopyBookingNumber || data.cW1BookingNumber) {
                        var bookingNumber = editCopyBookingNumber ? editCopyBookingNumber : data.cW1BookingNumber
                        setPageTitle('Edit Booking # ' + bookingNumber)
                    } 

                    if (data.originUNLOCOCode !== null) {
                        getLocationByUNLOCO(data.originUNLOCOCode, async data => {
                            if (data) {
                                const codeArray = [data]
                                setOriginUnlocoCodes(codeArray)
                                if (codeArray.length > 0) {
                                    setOriginUNLOCOCodeObject(codeArray[0])
                                    setValue('originUNLOCOCode', codeArray[0])
                                }
                            }
                        })
                    }   
            
                    if (data.destinationUNLOCOCode !== null) {
                        getLocationByUNLOCO(data.destinationUNLOCOCode, async data => {
                            if (data) {
                                const codeArray = [data]
                                setDestinationUnlocoCodes(codeArray)
                                if (codeArray.length > 0) {
                                    setDestinationUNLOCOCodeObject(codeArray[0])
                                    setValue('destinationUNLOCOCode', codeArray[0])
                                }
                            }
                        })
                    }

                    setOrganizationId(data.organizationId)
                    setValue('clientId', data.clientId)
                    setCurrentClient(clients.find(c => c.id === data.clientId))
                    setValue('orderReferenceNumber', data.orderReferenceNumber)
                    setValue('clientReferenceNumber', data.clientReferenceNumber)
                    setValue('transportModeCode', data.transportModeCode)
                    setValue('incoTermsCode', data.incoTermsCode)
                    setValue('commodityCode', data.commodityCode)
                    setValue('containerTypeCode', data.containerTypeCode)
                    setValue('serviceLevelCode', data.serviceLevelCode)
                    setValue('goodsDescription', data.goodsDescription)
                    setValue('piecesCount', data.piecesCount)
                    setValue('piecesUOM', data.piecesUOM)
                    setValue('weight', data.weight)
                    setValue('weightUOM', data.weightUOM)
                    setValue('volume', data.volume)
                    setValue('volumeUOM', data.volumeUOM)
                    setValue('requiredETD', data.requiredETD === '0001-01-01T00:00:00' ? null : data.requiredETD)
                    setValue('requiredETA', data.requiredETA === '0001-01-01T00:00:00' ? null : data.requiredETA)
                    setValue('requestedDelivery', data.requestedDelivery === '0001-01-01T00:00:00' ? null : data.requestedDelivery)
                    setValue('requiredPickup', data.requiredPickup === '0001-01-01T00:00:00' ? null : data.requiredPickup)
                    setValue('cargoReadyDate', data.cargoReadyDate === '0001-01-01T00:00:00' ? null : data.cargoReadyDate) 
                    setConsolidatedOrderList(data.linkedOrders)
                    setBookingOrders(data.linkedOrderHeaders)

                    if (data.shipmentNumber !== null) {
                        setShipmentNumber(data.shipmentNumber)
                    }

                    if (data.cW1BookingNumber !== null) {
                        setEditCopyBookingNumber(data.cW1BookingNumber)
                    }

                    const consignors = data.contacts.filter(
                        c => c.addressType === 'PIC'
                    )
                    if (consignors.length > 0) {
                        setValue('consignor', consignors[0])
                    }

                    const consignees = data.contacts.filter(
                        c => c.addressType === 'DLV'
                    )
                    if (consignees.length > 0) {
                        setValue('consignee', consignees[0])
                    }
                }).then(() => {
                    setRetrievingCW1Booking(false)
                    setReferredOrderAlert({
                        open: true,
                        success: true,
                        message: (editCopyBookingNumber ? 'Booking details' : 'Booking draft') + ' successfully loaded!'
                    })
                }).catch(error => {
                    setRetrievingCW1Booking(false)
                    setReferredOrderAlert({
                        open: true,
                        success: false,
                        message: 'Failed to load booking details.'
                    })
                })

                if (editCopyBookingNumber) {
                    localStorage.removeItem('editCopyBookingNumber')
                    localStorage.removeItem('editCopyBookingClientId')
                }

            }

        } else {
            setDidSetDestinationUnlocoCode(true)
            setDidSetOriginUnlocoCode(true)
        }

        getTypesData()

        if (!getValues('orderNumberSplit')) {
            setValue('orderNumberSplit', '0')
        }

    }, [clients])

    useEffect(() => {
        setValue('containerTypeMode', null)
        if (getValues('transportModeCode') === 'AIR') {
            setFilteredContainerModes(
                containerModes.filter(mode => mode.propertyKey === 'LSE')
            )
        } else if (getValues('transportModeCode') === 'SEA') {
            setFilteredContainerModes(
                containerModes.filter(
                    mode =>
                        mode.propertyKey === 'FCL' || mode.propertyKey === 'LCL'
                )
            )
        } else if (getValues('transportModeCode') === 'RAI') {
            setFilteredContainerModes(
                containerModes.filter(mode => mode.propertyKey === 'FCL')
            )
        } else if (getValues('transportModeCode') === 'ROA') {
            setFilteredContainerModes(
                containerModes.filter(
                    mode =>
                        mode.propertyKey === 'FCL' ||
                        mode.propertyKey === 'LCL' ||
                        mode.propertyKey === 'LTL' ||
                        mode.propertyKey === 'FTL'
                )
            )
        } else {
            setFilteredContainerModes(containerModes)
        }
    }, [watchTransportMode, transportModes])
    
    useEffect(() => {
        if (originUnlocoCodes.length > 0 && !didSetOriginUnlocoCode) {
            setOriginUNLOCOCodeObject(originUnlocoCodes[0])
            setDidSetOriginUnlocoCode(true)
        }
    }, [originUnlocoCodes])

    useEffect(() => {
        if (destinationUnlocoCodes.length > 0 && !didSetDestinationUnlocoCode) {
            setDestinationUNLOCOCodeObject(destinationUnlocoCodes[0])
            setDidSetDestinationUnlocoCode(true)
        }
    }, [destinationUnlocoCodes])

    useEffect(() => {

        setretrievingDataReferred(true)
        // get order number from local storage
        const orderNumber = localStorage.getItem('newBookingOrderNumber')
        const orderNumberSplit = localStorage.getItem('newBookingOrderNumberSplit')
        const buyerCode = localStorage.getItem('newBookingBuyerCode')
        const clientId = localStorage.getItem('newBookingClientId')
        const consolidatedOrderData = JSON.parse(localStorage.getItem('consolidatedOrderData'))
        const bookingOrders = JSON.parse(localStorage.getItem('bookingOrdersData'))

        if (!orderNumber || !orderNumberSplit || !clientId) {
            clearReferredOrderValues()
            setretrievingDataReferred(false)
            return
        }

        const client = clients.find(c => c.id === clientId)

        if (client) {  

            setretrievingDataReferred(true)

            fetchAllOrderData(orderNumber, orderNumberSplit, clientId)
            .then(([order]) => {
                if (!order.orderNumber) {
                    // handle this
                    clearReferredOrderValues()
                    setretrievingDataReferred(false)
                    return
                }
                
                let orderClientIsConsignor = false
                if (order.buyerCode === client.integrationCompanyCode) {
                    setIsConsignor(false)
                } else if (order.supplierCode === client.integrationCompanyCode) {
                    orderClientIsConsignor = true
                    setIsConsignor(true)
                } else {
                    // handle this
                    alert('Order client is not consignor or consignee.')
                    clearReferredOrderValues()
                    setretrievingDataReferred(false)
                    return
                }

                setretrievingDataReferred(false)
                setClientAddresses(client, orderClientIsConsignor)
                // clear consignee address
                setAddresses([])
                setFilteredAddresses([])
                searchAddresses('', orderClientIsConsignor, true).then(([searchedAddresses]) => {
                    if (orderClientIsConsignor) {
                        // set the consignee address
                        const consigneeAddress = searchedAddresses.find(a => a.orgCode === order.buyerCode) 
                        if (consigneeAddress)
                        {
                            setValue('consignee', consigneeAddress)
                            setValue('consignee.clientBuyerSupplierCode', consigneeAddress.orgCode)
                        }
                    } else {
                        const consignorAddress = searchedAddresses.find(a => a.orgCode === order.supplierCode)
                        // set the consignor address
                        if (consignorAddress)
                        {
                            setValue('consignor', consignorAddress)
                            setValue('consignor.clientBuyerSupplierCode', consignorAddress.orgCode)
                        }
                    }
                })

                // set extra values
                setValue('clientId', clientId)
                setCurrentClient(clients.find(c => c.id === clientId))

                if (consolidatedOrderData) {
                    setValue('orderReferenceNumber', consolidatedOrderData.orderNumber)
                    order.orderNumber = consolidatedOrderData.orderNumber
                    order.orderTransportMode = consolidatedOrderData.orderTransportMode
                    order.containerMode = consolidatedOrderData.containerMode
                    order.incoTerms = consolidatedOrderData.incoTerms
                    order.serviceLevel = consolidatedOrderData.serviceLevel
                    order.goodsDescription = consolidatedOrderData.goodsDescription
                    order.packs = consolidatedOrderData.packs
                    order.packType = consolidatedOrderData.packType
                    order.weight = consolidatedOrderData.weight
                    order.weightUOM = consolidatedOrderData.weightUOM
                    order.volume = consolidatedOrderData.volume
                    order.volumeUOM = consolidatedOrderData.volumeUOM
                    order.origin = consolidatedOrderData.origin
                    order.destination = consolidatedOrderData.destination
                    order.dateDepartEst = consolidatedOrderData.dateDepartEst
                    order.dateArriveEst = consolidatedOrderData.dateArriveEst
                    order.dateDeliveredEst = consolidatedOrderData.dateDeliveredEst
                    setConsolidatedOrderList(consolidatedOrderData.orderNumbersArray)
                    setBookingOrders(bookingOrders)
                } else {
                    setValue('orderReferenceNumber', orderNumber)
                    setConsolidatedOrderList([
                        {
                            orderNumber: orderNumber,
                            orderNumberSplit: parseInt(orderNumberSplit),
                            buyerCode: buyerCode
                        }
                    ])
                }

                setBookingDetailsFromOrder(order)
                clearReferredOrderValues()
                setReferredOrderAlert({
                    open: true,
                    success: true,
                    message: 'Order details successfully loaded!'
                })
            })
            .catch(error => {
                clearReferredOrderValues()
                setretrievingDataReferred(false)
                setReferredOrderAlert({
                    open: true,
                    success: false,
                    message: 'Failed to load order details.'
                })
            })

        }

    },[clients])

    const clearReferredOrderValues = () => {
        localStorage.removeItem('newBookingOrderNumber')
        localStorage.removeItem('newBookingOrderNumberSplit')
        localStorage.removeItem('newBookingBuyerCode')
        localStorage.removeItem('newBookingClientId')
        localStorage.removeItem('consolidatedOrderData')
        localStorage.removeItem('bookingOrdersData')
    }

    const fieldPanelMapping = [
        {
            field: 'clientId',
            panel: 'panel1'
        },
        {
            field: 'transportModeCode',
            panel: 'panel6'
        },
        {
            field: 'incoTermsCode',
            panel: 'panel6'
        },
        {
            field: 'commodityCode',
            panel: 'panel6'
        },
        {
            field: 'containerTypeCode',
            panel: 'panel6'
        },
        {
            field: 'serviceLevelCode',
            panel: 'panel6'
        },
        {
            field: 'goodsDescription',
            panel: 'panel6'
        },
        {
            field: 'piecesCount',
            panel: 'panel6'
        },
        {
            field: 'piecesUOM',
            panel: 'panel6'
        },
        {
            field: 'weight',
            panel: 'panel6'
        },
        {
            field: 'weightUOM',
            panel: 'panel6'
        },
        {
            field: 'volume',
            panel: 'panel6'
        },
        {
            field: 'volumeUOM',
            panel: 'panel6'
        },
        {
            field: 'consignor',
            panel: 'panel4'
        },
        {
            field: 'consignee',
            panel: 'panel5'
        },
        {
            field: 'cargoReadyDate',
            panel: 'panel7'
        },
        {
            field: 'originUNLOCOCode',
            panel: 'panel6'
        },
        {
            field: 'destinationUNLOCOCode',
            panel: 'panel6'
        }
    ]

    const contactsAreEqual = () => {
        const consignor = getValues('consignor')
        const consignee = getValues('consignee')
        if (consignor !== undefined && consignee !== undefined) {
            if (
                consignor.addressLine1 === consignee.addressLine1 &&
                consignor.city === consignee.city &&
                consignor.zipCode === consignee.zipCode &&
                consignor.state === consignee.state &&
                consignor.countryCode === consignee.countryCode
            ) {
                return true
            }
        }
        return false
    }

    const shipmentIdKey = () => {
        return key + '.shipmentId'
    }

    const handleSetIsConsignor = (event, newValue) => {
        const clientId = getValues('clientId')
        if (clientId) {
            const client = clients.find(c => c.id === clientId)
            if (client) {  
                setClientAddresses(client, newValue)
            }
        }
        setIsConsignor(newValue);
        searchAddresses('', newValue, true).then(([searchedAddresses]) => {
            // something
        })

      };

    const setDataTypes = data => {

        const values1 = data.filter(item => item.propertyType === 'TRANSPORT_MODE')
        if (values1.length > 0) {
            setTransportModes(values1.filter(item => item.isAvailable === true))
            const defaultTransportMode = values1.filter(item => item.isDefault === true)
            if (defaultTransportMode.length > 0) {
                setValue('transportModeCode', defaultTransportMode[0].propertyKey)
            }
        }

        const values2 = data.filter(item => item.propertyType === 'INCO_TERMS')
        if (values2.length > 0) {
            setIncoTerms(values2.filter(item => item.isAvailable === true))
            const defaultIncoTerms = values2.filter(item => item.isDefault === true)
            if (defaultIncoTerms.length > 0) {
                setValue('incoTermsCode', defaultIncoTerms[0].propertyKey)
            }
        }

        const values3 = data.filter(item => item.propertyType === 'COMMODITY')
        if (values3.length > 0) {
            setCommodities(values3.filter(item => item.isAvailable === true))
            const defaultCommodity = values3.filter(item => item.isDefault === true)
            if (defaultCommodity.length > 0) {
                setValue('commodityCode', defaultCommodity[0].propertyKey)
            }
        }

        const values4 = data.filter(item => item.propertyType === 'CONTAINER_TYPE_MODE')
        if (values4.length > 0) {
            setContainerModes(values4.filter(item => item.isAvailable === true))
            const defaultContainerMode = values4.filter(item => item.isDefault === true)
            if (defaultContainerMode.length > 0) {
                setValue('containerTypeCode', defaultContainerMode[0].propertyKey)
            }
        }

        const values5 = data.filter(item => item.propertyType === 'SERVICE_LEVEL')
        if (values5.length > 0) {
            setServiceLevels(values5.filter(item => item.isAvailable === true))
            const defaultServiceLevel = values5.filter(item => item.isDefault === true)
            if (defaultServiceLevel.length > 0) {
                setValue('serviceLevelCode', defaultServiceLevel[0].propertyKey)
            }
        }

        const values6 = data.filter(item => item.propertyType === 'PIECES_UOM')
        if (values6.length > 0) {
            setPieceUnits(values6.filter(item => item.isAvailable === true))
            const defaultPiecesUOM = values6.filter(item => item.isDefault === true)
            if (defaultPiecesUOM.length > 0) {
                setValue('piecesUOM', defaultPiecesUOM[0].propertyKey)
            }
        }

        const values7 = data.filter(item => item.propertyType === 'WEIGHT_UOM')
        if (values7.length > 0) {
            setWeightUnits(values7.filter(item => item.isAvailable === true))
            const defaultWeightUOM = values7.filter(item => item.isDefault === true)
            if (defaultWeightUOM.length > 0) {
                setValue('weightUOM', defaultWeightUOM[0].propertyKey)
            }
        }

        const values8 = data.filter(item => item.propertyType === 'VOLUME_UOM')
        if (values8.length > 0) {
            setVolumeUnits(values8.filter(item => item.isAvailable === true))
            const defaultVolumeUOM = values8.filter(item => item.isDefault === true)
            if (defaultVolumeUOM.length > 0) {
                setValue('volumeUOM', defaultVolumeUOM[0].propertyKey)
            }
        }
    }

    const prepareData = data => {
        const newData = _.clone(_.omitBy(data, _.isNil))
        newData['originUNLOCOCode'] = originUNLOCOCodeObject?.unloco
        newData['destinationUNLOCOCode'] = destinationUNLOCOCodeObject?.unloco
        newData['piecesCount'] = parseFloat(data.piecesCount)
        newData['weight'] = parseFloat(data.weight)
        newData['volume'] = parseFloat(data.volume)
        newData['organizationId'] = organizationId
        newData['controllingCustomerCode'] = controllingCustomerCode

        const newConsignor = _.clone(data.consignor)
        newConsignor['addressType'] = 'PIC'

        const newConsignee = _.clone(data.consignee)
        newConsignee['addressType'] = 'DLV'

        newData['contacts'] = [newConsignor, newConsignee]
        newData['linkedOrders'] = consolidatedOrderList
        return newData
    }

    const handleFormSubmit = data => {
        if (contactsAreEqual()) {
            setBookingAlert({
                open: true,
                success: false,
                message: 'Consignor and consignee addresses are the same.'
            })
        } else {
            const preparedData = prepareData(data)
            saveShipmentData(preparedData)
        }
    }

    const handleInvalidFormSubmit = (errors) => {
        let panels = []
        for (const error in errors) {
            // get the panel name for the field
            const panel = fieldPanelMapping.find(f => f.field === error)?.panel
            if (panel && !panels.includes(panel)) {
                panels.push(panel)
            }
        }
        setExpandedPanels(panels)
    }

    const handleCloseAlert = () => {
        setBookingAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const handleCloseOrderAlert = () => {
        setOrderAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const handleCloseReferredOrderAlert = () => {
        setReferredOrderAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const updateAddressInfo = data => {
        if (data === null) {
            return
        }

        const key = !isConsignor ? 'consignor' : 'consignee'

        setValue(`${key}.name`, data.name)
        setValue(`${key}.portCode`, data.portCode)
        setValue(`${key}.addressLine1`, data.addressLine1)
        setValue(`${key}.city`, data.city)
        setValue(`${key}.state`, data.state)
        setValue(`${key}.countryCode`, data.countryCode)
        setValue(`${key}.zipCode`, data.zipCode)
        setValue(`${key}.clientBuyerSupplierCode`, data.orgCode)
        setselectedAddressForeignKey(data.psaClientBuyerSupplierFK)
        setControllingCustomerCode(data.controllingCustomerCode)
    }

    const updateAddressInfoFromSelection = (data, sourceParty) => {
        if (data === null) {
            return
        }

        setValue(`${sourceParty}.name`, data.name)
        setValue(`${sourceParty}.portCode`, data.portCode)
        setValue(`${sourceParty}.addressLine1`, data.addressLine1)
        setValue(`${sourceParty}.city`, data.city)
        setValue(`${sourceParty}.state`, data.state)
        setValue(`${sourceParty}.countryCode`, data.countryCode)
        setValue(`${sourceParty}.zipCode`, data.zipCode)
        setValue(`${sourceParty}.clientBuyerSupplierCode`, data.orgCode)

    }

    const renderBookingAlert = () => {
        return bookingAlert.open ? (
            <div className="row mb-0">
                <AlertMessage
                    open={bookingAlert.open}
                    success={bookingAlert.success}
                    message={bookingAlert.message}
                    onClose={() => handleCloseAlert()}
                />
            </div>
        ) : null
    }

    const renderReferredOrderAlert = () => {
        return referredOrderAlert.open ? (
            <Alert
                onClose={() => handleCloseReferredOrderAlert()}
                severity={referredOrderAlert.success ? 'success' : 'error'}>
                {referredOrderAlert.message}
            </Alert>
        ) : null
    }

    useEffect(() => {
        getAppInfo()
    }, [])

    const getAppInfo = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch('api/app/info', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            const data = await response.json()
            setcolorThemePrimaryRGB(data.colorThemePrimaryRGB)
            setcolorThemeSecondaryRGB(data.colorThemeSecondaryRGB)
            setcolorThemeTertiaryRGB(data.colorThemeTertiaryRGB)
            setcolorThemeRGB(data.colorThemeRGB)
            setcolorThemeTextRGB(data.colorThemeTextRGB)
            setOrganization(data)
        }
    }
    const renderInput = (
        label,
        name,
        required = true,
        inputProps = {},
        customKey = null,
        customSubKey = null,
        disabled = false
    ) => {
        return (
            <FormControl style={{ width: '100%' }}>
                <Grid container spacing={0} alignItems="flex-end">
                    <Grid item style={{ width: '100%' }}>
                        <Controller
                            rules={
                                required
                                    ? { required: 'This field is required.' }
                                    : {}
                            }
                            name={name}
                            control={control}
                            defaultValue={''}
                            render={({
                                onChange,
                                onBlur,
                                value,
                                name
                            }) => (
                                <TextField
                                    style={{ width: '80%' }}
                                    label={label}
                                    onChange={onChange}
                                    inputProps={inputProps}
                                    placeholder={`Enter ${label}`}
                                    name={name}
                                    value={value ? value : ''}
                                    disabled={disabled}
                                    read
                                />
                            )}
                        />

                        {errors[name] && (
                            <p className="m-0 message-validation">
                                {errors[name].message}
                            </p>
                        )}
                        {customKey !== undefined &&
                            customSubKey !== undefined &&
                            customKey !== null &&
                            customSubKey !== null &&
                            errors[customKey] &&
                            errors[customKey][customSubKey] && (
                                <p className="m-0 message-validation">
                                    {errors[customKey][customSubKey].message}
                                </p>
                            )}
                    </Grid>
                </Grid>
            </FormControl>
        )
    }

    const getCargoReadyDate = (currentorg) => {
        if (getOrgNameFromOrganization(currentorg) === 'EFL') {
            return (
                <Grid
                    item
                    xl={3}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className="mb-2 xxl-2">
                    {renderDatePicker(
                        'cargoReadyDate',
                        'Cargo Ready Date',
                        currentorg,
                        true
                    )}
                </Grid>
            )
        }
        return null
    }

    const renderDateSelectors = (currentorg) => {
        switch (getOrgNameFromOrganization(currentorg)) {
            case 'OWL':
                return renderDateSelectorsOWL(currentorg)
            default:
                return renderDateSelectorsDefault(currentorg)
        }
    }

    const renderDateSelectorsDefault = (currentorg) => {
        return (
            <Grid
                container
                direction="row"
                space={3}
                spacing={4}
                className="mb-4 button-no-padding">
                <Grid
                    item
                    xl={3}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className="mb-2 xxl-2">
                    {renderDatePicker(
                        'requiredPickup',
                        'Required Pickup',
                        currentorg
                    )}
                </Grid>
                <Grid
                    item
                    xl={3}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className="mb-2 xxl-2">
                    {renderDatePicker(
                        'requiredETD',
                        'Required ETD',
                        currentorg
                    )}
                </Grid>
                <Grid
                    item
                    xl={3}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className="mb-2 xxl-2">
                    {renderDatePicker(
                        'requiredETA',
                        'Required ETA',
                        currentorg
                    )}
                </Grid>
                <Grid
                    item
                    xl={3}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className="mb-2 xxl-2">
                    {renderDatePicker(
                        'requestedDelivery',
                        'Requested Delivery',
                        currentorg
                    )}
                </Grid>
                {getCargoReadyDate(currentorg)}
            </Grid>
        )
    }

    const renderDateSelectorsOWL = (currentorg) => {
        return (
            <Grid
                container
                direction="row"
                space={3}
                spacing={4}
                className="mb-4 button-no-padding">
                <Grid
                    item
                    xl={3}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className="mb-2 xxl-2">
                    {renderDatePicker(
                        'requiredPickup',
                        'Cargo Ready Date',
                        currentorg
                    )}
                </Grid>
            </Grid>
        )
    }

    const setClientAddresses = (client, isConsignor) => {

        const setKey = isConsignor ? 'consignor' : 'consignee'
        const clearKey = isConsignor ? 'consignee' : 'consignor'

        setValue(`${setKey}.name`, client.name)
        setValue(`${setKey}.addressLine1`, client.contactAddressLine1)
        setValue(`${setKey}.city`, client.contactAddressCity)
        setValue(`${setKey}.state`, client.contactAddressRegion)
        setValue(`${setKey}.countryCode`, client.contactAddressCountryCode)
        setValue(`${setKey}.zipCode`, client.contactAddressZipCode)
        setValue(`${setKey}.clientBuyerSupplierCode`, client.integrationCompanyCode)

        setValue(`${clearKey}.name`, '')
        setValue(`${clearKey}.addressLine1`, '')
        setValue(`${clearKey}.city`, '')
        setValue(`${clearKey}.state`, '')
        setValue(`${clearKey}.countryCode`, '')
        setValue(`${clearKey}.zipCode`, '')
        setValue(`${clearKey}.clientBuyerSupplierCode`, '')
    }

    const setBookingDetailsFromOrder = async (order) => {

        if (order === null) {
            return
        }

        setValue('orderReferenceNumber', order.orderNumber)
        setValue('transportModeCode', order.orderTransportMode)
        setValue('containerTypeCode', order.containerMode)
        setValue('incoTermsCode', order.incoTerms)
        setValue('serviceLevelCode', order.serviceLevel)
        setValue('commodityCode', '') // not available in order
        setValue('goodsDescription', order.goodsDescription)

        setValue('piecesCount', order.packs)
        setValue('piecesUOM', order.packType)
        setValue('weight', order.weight)
        setValue('weightUOM', order.weightUOM)
        setValue('volume', order.volume)
        setValue('volumeUOM', order.volumeUOM)


        if (order.origin !== null) {
            getLocationByUNLOCO(order.origin, async data => {
                const codeArray = [data]
                setOriginUnlocoCodes(codeArray)
                if (codeArray.length > 0) {
                    setOriginUNLOCOCodeObject(codeArray[0])
                    setValue('originUNLOCOCode', codeArray[0])
                }
            })
        }   

        if (order.destination !== null) {
            getLocationByUNLOCO(order.destination, async data => {
                const codeArray = [data]
                setDestinationUnlocoCodes(codeArray)
                if (codeArray.length > 0) {
                    setDestinationUNLOCOCodeObject(codeArray[0])
                    setValue('destinationUNLOCOCode', codeArray[0])
                }
            })
        }

        setValue('requiredETD', order.dateDepartEst ? order.dateDepartEst : null)
        setValue('requiredETA', order.dateArriveEst ? order.dateArriveEst : null)
        setValue('requestedDelivery', order.dateDeliveredEst ? order.dateDeliveredEst : null)
        setValue('requiredPickup', null)
    }

    const handleSelectChange = (e, name) => {
        if (name === 'clientId') {
            const client = clients.find(c => c.id === e.target.value)
            setClientAddresses(client, isConsignor)
            // clear consignee address
            setAddresses([])
            setFilteredAddresses([])
            setOrgConsigneeAddresses([])
            setFilteredOrgConsigneeAddresses([])
            setOrgConsignorAddresses([])
            setFilteredOrgConsignorAddresses([])
            searchAddresses('', isConsignor, true).then(([searchedAddresses]) => {
                // something
            })
            setCurrentClient(client)

        }
    }

    const renderSelect = (
        label,
        name,
        data,
        keyValue = 'key',
        valueKey = 'value',
        showKeyInValue = false
    ) => {
        return (
            <FormControl style={{ width: '100%' }}>
                <InputLabel>{label}</InputLabel>
                <Controller
                    name={name}
                    rules={{ required: 'This field is required.' }}
                    control={control}
                    defaultValue={''}
                    render={({
                        onChange,
                        onBlur,
                        value,
                        name
                    }) => (
                            <Select
                                style={{ width: '100%' }}
                                label={label}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelectChange(e, name)
                                  }}
                                value={value ? value : ""}
                                name={name}
                                input={<Input />}>
                                {data.map(option => (
                                    <MenuItem
                                        key={option[keyValue]}
                                        value={option[keyValue]}>
                                        {showKeyInValue
                                            ? `${option[keyValue]} - ${option[valueKey]}`
                                            : option[valueKey]}
                                    </MenuItem>
                                ))}
                            </Select>
                    )}
                />
                {errors[name] && (
                    <p className="m-0 message-validation">
                        {errors[name].message}
                    </p>
                )}
            </FormControl>
        )
    }

    const renderAutocomplete = (
        data,
        passedValue,
        label,
        name,
        selectChangeCallback = () => {},
        textChangeCallback = () => {}
    ) => {
        return (
            <FormControl style={{ width: '100%' }}>
                <InputLabel>{label}</InputLabel>
                <Controller
                    name={name}
                    rules={{ required: 'This field is required.' }}
                    control={control}
                    defaultValue={''}
                    render={({
                        onChange,
                        onBlur,
                        value=passedValue,
                        name
                    }) => (
                        <Autocomplete
                            style={{ width: '100%' }}
                            value={value}
                            getOptionSelected={(option, value) => {
                                return option.unLoco === value.unLoco
                            }}
                            onChange={
                                (e, value) => {
                                    onChange(value)
                                    selectChangeCallback(e, value)
                                }
                            }
                            getOptionLabel={option => option ? (option.unloco + ' - ' + option.portName) : ''}
                            options={data}
                            loading={false}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    style={{ width: '100%' }}
                                    onChange={textChangeCallback}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps
                                    }}
                                />
                            )}
                        />
                    )}
                />
                {errors[name] && (
                    <p className="m-0 message-validation">
                        {errors[name].message}
                    </p>
                )}
            </FormControl>
        )
    }

    const renderTitle = (title, width = 12) => {
        return (
            <Grid item xs={width}>
                <Grid container>
                    <Grid>
                        <Typography gutterBottom variant="p">
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderUnitSection = (key, selectKey, data) => {
        const cKey = voca.capitalize(key)
        return (
            <div class="auto-compling-col">
                <Grid container item xs spacing={3}>
                    <Grid item xs={6}>
                        <div className="">{renderInput(cKey, key)}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            {renderSelect(
                                `${cKey} Unit`,
                                selectKey,
                                data,
                                'propertyKey',
                                'value',
                                true
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const getClients = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch('api/clients/getclientslist?module=createbooking', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
        if (response.ok) {
            const data = await response.json()

            setClients(data)
            if (data.length > 0) {
                if (!getValues('clientId')) {
                    setValue('clientId', data[0].id)
                    setCurrentClient(data[0])

                    const setKey = isConsignor ? 'consignor' : 'consignee'

                    setValue(`${setKey}.name`, data[0].name)
                    setValue(`${setKey}.addressLine1`, data[0].contactAddressLine1)
                    setValue(`${setKey}.city`, data[0].contactAddressCity)
                    setValue(`${setKey}.state`, data[0].contactAddressRegion)
                    setValue(`${setKey}.countryCode`, data[0].contactAddressCountryCode)
                    setValue(`${setKey}.zipCode`, data[0].contactAddressZipCode)
                    setValue(`${setKey}.clientBuyerSupplierCode`, data[0].integrationCompanyCode)

                    setAddresses([])
                    setFilteredAddresses([])
                    searchAddresses('', isConsignor, true).then(([searchedAddresses]) => {
                        // something
                    })
                }
                setOrganizationId(data[0].psaOrganizationId)
            }
        }
    }

    const getTypesData = async () => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetDataTypes?dataTypesRequested=ALL',
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            setDataMapping(JSON.stringify(data))
            setDataTypes(data)
        }
    }

    const getLinkedTypesData = async (linkId) => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetLinkedDataTypes?selectedDataTypeId=' + linkId,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            setDataTypes(data)
        }
    }

    const setLinkedOrderColumnDefinitions = async () => {
        const clientId = getValues('clientId')
        const data = await retrieveView(clientId, 'CreateBookingLinkedOrderHeaders')
        if (!data) {
            setLinkedOrderHeaderColumns([])
        } else {
            const columnDefinitions = data?.columnDefinitionsArray
            let parsedColumns = columnDefinitions?.filter(e => e.visible === true)
            // need to map field and property to label and key
            parsedColumns = parsedColumns?.map(e => {
                return {
                    label: e.title,
                    key: e.value
                }       
            })
            setLinkedOrderHeaderColumns(parsedColumns)
        }
    }

    const searchUNLOCO = async (query, isOrigin = true, isName = true) => {
        getUNLOCO(query, isName, async data => {
            if (isOrigin) {
                setOriginUnlocoCodes(data)
            } else {
                setDestinationUnlocoCodes(data)
            }
        })
    }

    const searchAddresses = async (query, isShipper, isPrimary, fromShipper) => {

        const token = await authService.getAccessToken()
        const clientId = getValues('clientId')

        if (!clientId) {
            return
        }

        let selectedPartyForeignKey = selectedAddressForeignKey

        if ((isShipper && fromShipper) || (!isShipper && !fromShipper)) {
            selectedPartyForeignKey = currentClient?.sourcePK
        }

        const response = await fetch(
            `api/DataManagement/GetClientOrgAddresses?clientId=${clientId}${
                isShipper ? '&IsSearchingShipper=true' : ''
            }&SearchQuery=${query}&IsPrimary=${isPrimary}&CW1OrgPK=${selectedPartyForeignKey ? selectedPartyForeignKey : ''}`,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            if (isPrimary) {
                setAddresses(data)
                setFilteredAddresses(data)
            }
            return [data]
        }
    }

    const saveShipmentData = async data => {

        if (shipmentNumber !== null) {
            data['shipmentNumber'] = shipmentNumber
        }
        if (editCopyBookingNumber !== null) {
            data['cW1BookingNumber'] = editCopyBookingNumber
        }

        const nextBookingStep = getNextEnabledBookingStep(0, currentClient.bookingSteps, data.containerTypeCode)
        const token = await authService.getAccessToken()

        await fetch('api/createShipments/PutCreateShipment?sourceForm=bookingDetails', {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(async response => {
                let results = null
                if (response.ok) {
                    const newData = await response.json()

                    sessionStorage.setItem(
                        shipmentIdKey(),
                        JSON.stringify(data)
                    )

                    setBookingAlert({
                        open: true,
                        success: true,
                        message: 'Shipment information is saved. Redirecting...'
                    })

                    sessionStorage.setItem(
                        shipmentIdKey(),
                        newData.shipmentNumber
                    )

                    setTimeout(() => {
                        window.location.href = nextBookingStep.href
                    }, 3000)
                } else {
                    setBookingAlert({
                        open: true,
                        success: false,
                        message:
                            'Sorry, there was an error while saving this details.'
                    })
                }
            })
            .catch(error => {
                setBookingAlert({
                    open: true,
                    success: false,
                    message:
                        'Sorry, there was an error while saving this Shipment information.'
                })
            })
    }

    const renderDatePicker = (name, label, organization, required) => {
        return (
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                        name={name}
                        rules={
                            required
                                ? { required: 'This field is required.' }
                                : {}
                        }
                        control={control}
                        defaultValue={null}
                        render={({
                            onChange,
                            onBlur,
                            value,
                            name
                        }) => (
                            <KeyboardDatePicker
                                margin="normal"
                                variant="inline"
                                label={label}
                                autoOk={true}
                                value={value}
                                name={name}
                                format={organization.pickerDateFormat}
                                onChange={(e) => {
                                    onChange(e)
                                    setValue(name, dateFns.format(e, 'YYYY-MM-DD'))
                                }}
                            />
                        )}
                    />
                </MuiPickersUtilsProvider>

                {errors[name] && (
                    <p className="m-0 message-validation">
                        {errors[name].message}
                    </p>
                )}
            </div>
        )
    }

    const handleModalOpen = () => {
        setFilteredAddresses(addresses)
        setModalOpen(true)
    }

    const handleConsignorModalOpen = () => {
        searchAddresses('', isConsignor, false, true).then(([searchedAddresses]) => {
            setOrgConsignorAddresses(searchedAddresses)
            setFilteredOrgConsignorAddresses(searchedAddresses)
            setConsignorAddressesModalOpen(true)
        })
    }

    const handleConsigneeModalOpen = () => {
        searchAddresses('', isConsignor, false, false).then(([searchedAddresses]) => {
            setOrgConsigneeAddresses(searchedAddresses)
            setFilteredOrgConsigneeAddresses(searchedAddresses)
            setConsigneeAddressesModalOpen(true)
        })
    }



    const handleModalClose = () => {
        setModalOpen(false)
        setFilteredAddresses(addresses)
    }

    const handleConsignorAddressModalClose = () => {
        setConsignorAddressesModalOpen(false)
        setFilteredOrgConsignorAddresses(orgConsignorAddresses)
    }

    const handleConsigneeAddressModalClose = () => {
        setConsigneeAddressesModalOpen(false)
        setFilteredOrgConsigneeAddresses(orgConsigneeAddresses)
    }

    const handleACCChange = (panel) => (event) => {
        setExpandedPanels([panel])
    }

    const populateOrderData = async (orderNumber, orderNumberSplit, clientId) =>{
        setRetrievingOrder(true)
        fetchAllOrderData(orderNumber, orderNumberSplit, clientId)
            .then(([order]) => {
                if (!order.orderNumber) {
                    setOrderAlert({
                        open: true,
                        success: false,
                        message: 'Failed to retrieve order details.'
                    })
                    setRetrievingOrder(false)
                    return
                }
                setRetrievingOrder(false)
                setBookingDetailsFromOrder(order)
                setOrderAlert({
                    open: true,
                    success: true,
                    message: 'Order details successfully retrieved!'
                })
            })
            .catch(error => {
                setRetrievingOrder(false)
                setOrderAlert({
                    open: true,
                    success: false,
                    message: 'Failed to retrieve order details.'
                })
            })
    }

    const fetchAllOrderData = async (orderNumber, orderNumberSplit, clientId) => {
        const queryString = require('query-string')

        const qs = {
            ClientId: clientId,
            OrderSearchNumber: btoa(orderNumber).replace('/', '_'),
            OrderSearchNumberSplit: orderNumberSplit
        }

        const token = await authService.getAccessToken()

        const [
            orderResponse,
        ] = await Promise.all([
            fetch('api/orders/GetOrder?' + queryString.stringify(qs), {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            })
        ])

        const order = await orderResponse.json()

        return [order]
    }

    return (
        <div className="w-100">
            <CardContent className="p-0 psa-shipment-paper-card">
                {renderReferredOrderAlert()}
                <Paper className="paper psashipmentpaper">
                {retrievingDataReferred || retrievingCW1Booking ? (<LinearProgress />) : (
                        <form
                            className={classes.container}
                            onSubmit={handleSubmit(handleFormSubmit, handleInvalidFormSubmit)}
                            noValidate
                            autoComplete="off">
                            <Grid container spacing={1} direction="column">
                                <Grid item xs={12} className="p-0 w-100">
                                    <div className="create-shipment-stepper mx-1">
                                        <ShipmentCreationProgress
                                            client={currentClient}
                                            index={0} 
                                        />
                                    </div>
                                </Grid>

                                <div class="accordian-body-padding">
                                    <Grid
                                        className="mb-4 w-100"
                                        direction="column">
                                        <Grid item xs={12}>
                                            <div className="no-p-0 custom-psa-accordian">
                                                <Accordion
                                                    square
                                                    className="w-100"
                                                    expanded={expandedPanels.includes('panel1')}
                                                    onChange={handleACCChange(
                                                        'panel1'
                                                    )}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: colorThemePrimaryRGB,
                                                                    color: colorThemePrimaryRGB
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }>
                                                            {' '}
                                                            <h5
                                                                style={{
                                                                    color: colorThemePrimaryRGB
                                                                }}>
                                                                {renderTitle(
                                                                    'Client'
                                                                )}
                                                            </h5>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2 ">
                                                                    {renderSelect(
                                                                        'Client',
                                                                        'clientId',
                                                                        clients,
                                                                        'id',
                                                                        'name'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2 ">
                                                                    <ToggleButtonGroup
                                                                        value={isConsignor}
                                                                        exclusive
                                                                        onChange={handleSetIsConsignor}
                                                                        aria-label="is consignor"
                                                                        >
                                                                        <ToggleButton style={{
                                                                            width: '150px', 
                                                                            backgroundColor: isConsignor ? colorThemePrimaryRGB : null,
                                                                            color: isConsignor ? colorThemeTextRGB : null
                                                                            }} value={true} aria-label="Consignor">
                                                                            Consignor
                                                                        </ToggleButton>
                                                                        <ToggleButton style={{
                                                                            width: '150px', 
                                                                            backgroundColor: isConsignor ? null : colorThemePrimaryRGB,
                                                                            color: isConsignor ? null : colorThemeTextRGB
                                                                            }} value={false} aria-label="Consignee">
                                                                            Consignee
                                                                        </ToggleButton>
                                                                    </ToggleButtonGroup>
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: colorThemePrimaryRGB
                                                    }}>
                                                </div>
                                                <Accordion
                                                    square
                                                    className="w-100"
                                                    expanded={expandedPanels.includes('panel3')}
                                                    onChange={handleACCChange(
                                                        'panel3'
                                                    )}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: colorThemePrimaryRGB,
                                                                    color: colorThemePrimaryRGB
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }>
                                                            {' '}
                                                            <h5
                                                                style={{
                                                                    color: colorThemePrimaryRGB
                                                                }}>
                                                                {renderTitle(
                                                                    'References'
                                                                )}
                                                            </h5>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-2 mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Order Reference',
                                                                        'orderReferenceNumber',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Client Reference',
                                                                        'clientReferenceNumber',
                                                                        false
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: colorThemePrimaryRGB
                                                    }}></div>
                                                <Accordion
                                                    expanded={expandedPanels.includes('panel4')}
                                                    onChange={handleACCChange(
                                                        'panel4'
                                                    )}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: colorThemePrimaryRGB,
                                                                    color: colorThemePrimaryRGB
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }>
                                                            {' '}
                                                            <h5
                                                                style={{
                                                                    color: colorThemePrimaryRGB
                                                                }}>
                                                                {renderTitle(
                                                                    'Consignor'
                                                                )}
                                                            </h5>
                                                        </Typography>
                                                        <FormControlLabel
                                                            aria-label="SelectConsignor"
                                                            onClick={(event) => event.stopPropagation()}
                                                            onFocus={(event) => event.stopPropagation()}
                                                            control={!isConsignor ? (
                                                                <IconButton
                                                                    className="MuiButton-containedPrimary addbtn mr-2 text-white"
                                                                    style={{
                                                                        background: colorThemePrimaryRGB,
                                                                        border: colorThemePrimaryRGB,
                                                                        color: colorThemeTextRGB
                                                                    }}
                                                                    onClick={() => {
                                                                        handleModalOpen()
                                                                    }}
                                                                    variant="contained"
                                                                    color="primary">
                                                                    <AddCircleIcon />
                                                                    Select Consignor
                                                                </IconButton>
                                                            ) : <></>}
                                                            label=''
                                                        />                                                        
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={3}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Consignor Code',
                                                                        'consignor.clientBuyerSupplierCode',
                                                                        true,
                                                                        {readOnly: true},
                                                                        'consignor',
                                                                        'clientBuyerSupplierCode'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Consignor Name',
                                                                        'consignor.name',
                                                                        true,
                                                                        {readOnly: true},
                                                                        'consignor',
                                                                        'name'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Address',
                                                                        'consignor.addressLine1',
                                                                        true,
                                                                        {readOnly: true},
                                                                        'consignor',
                                                                        'addressLine1'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'City',
                                                                        'consignor.city',
                                                                        true,
                                                                        {readOnly: true},
                                                                        'consignor',
                                                                        'city'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'State',
                                                                        'consignor.state',
                                                                        false,
                                                                        {readOnly: true},
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Postal Code',
                                                                        'consignor.zipCode',
                                                                        false,
                                                                        {readOnly: true},
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Country (2 Characters)',
                                                                        'consignor.countryCode',
                                                                        true,
                                                                        {
                                                                            maxLength: 2,
                                                                            readOnly: true
                                                                        },
                                                                        'consignor',
                                                                        'countryCode'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={8}>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={2}>
                                                                    <FormControlLabel
                                                                        aria-label="Select Consignor Address"
                                                                        onClick={(event) => event.stopPropagation()}
                                                                        onFocus={(event) => event.stopPropagation()}
                                                                        control={
                                                                            <IconButton
                                                                                className="MuiButton-containedPrimary addbtn mr-2 text-white"
                                                                                style={{
                                                                                    background: colorThemePrimaryRGB,
                                                                                    border: colorThemePrimaryRGB,
                                                                                    color: colorThemeTextRGB
                                                                                }}
                                                                                onClick={() => {
                                                                                    setFilteredOrgConsignorAddresses(orgConsignorAddresses)
                                                                                    handleConsignorModalOpen()
                                                                                }}
                                                                                variant="contained"
                                                                                color="primary">
                                                                                <AddCircleIcon />
                                                                                Select Address
                                                                            </IconButton>
                                                                        }
                                                                        label=''
                                                                    />             
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: colorThemePrimaryRGB
                                                    }}></div>
                                                <Accordion
                                                    expanded={expandedPanels.includes('panel5')}
                                                    onChange={handleACCChange(
                                                        'panel5'
                                                    )}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: colorThemePrimaryRGB,
                                                                    color: colorThemePrimaryRGB
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }>
                                                            {' '}
                                                            <h5
                                                                style={{
                                                                    color: colorThemePrimaryRGB
                                                                }}>
                                                                {' '}
                                                                {renderTitle(
                                                                    'Consignee'
                                                                )}
                                                            </h5>
                                                        </Typography>
                                                        <FormControlLabel
                                                            aria-label="SelectConsignee"
                                                            onClick={(event) => event.stopPropagation()}
                                                            onFocus={(event) => event.stopPropagation()}
                                                            control={isConsignor ? (
                                                                <IconButton
                                                                    className="MuiButton-containedPrimary addbtn mr-2 text-white"
                                                                    style={{
                                                                        background: colorThemePrimaryRGB,
                                                                        border: colorThemePrimaryRGB,
                                                                        color: colorThemeTextRGB
                                                                    }}
                                                                    onClick={() => {
                                                                        handleModalOpen()
                                                                    }}
                                                                    variant="contained"
                                                                    color="primary">
                                                                    <AddCircleIcon />
                                                                    Select Consignee
                                                                </IconButton>
                                                            ) : <></>}
                                                            label=''
                                                        />     
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={3}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Consignee Code',
                                                                        'consignee.clientBuyerSupplierCode',
                                                                        true,
                                                                        {readOnly: true},
                                                                        'consignee',
                                                                        'clientBuyerSupplierCode'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Consignee Name',
                                                                        'consignee.name',
                                                                        true,
                                                                        {readOnly: true},
                                                                        'consignee',
                                                                        'name'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Address',
                                                                        'consignee.addressLine1',
                                                                        true,
                                                                        {readOnly: true},
                                                                        'consignee',
                                                                        'addressLine1'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'City',
                                                                        'consignee.city',
                                                                        true,
                                                                        {readOnly: true},
                                                                        'consignee',
                                                                        'city'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'State',
                                                                        'consignee.state',
                                                                        false,
                                                                        {readOnly: true}
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Postal Code',
                                                                        'consignee.zipCode',
                                                                        false,
                                                                        {readOnly: true}
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Country (2 Characters)',
                                                                        'consignee.countryCode',
                                                                        true,
                                                                        {
                                                                            maxLength: 2,
                                                                            readOnly: true
                                                                        },
                                                                        'consignee',
                                                                        'countryCode'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={8}>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={2}>
                                                                    <FormControlLabel
                                                                        aria-label="Select Consignee Address"
                                                                        onClick={(event) => event.stopPropagation()}
                                                                        onFocus={(event) => event.stopPropagation()}
                                                                        control={
                                                                            <IconButton
                                                                                className="MuiButton-containedPrimary addbtn mr-2 text-white"
                                                                                style={{
                                                                                    background: colorThemePrimaryRGB,
                                                                                    border: colorThemePrimaryRGB,
                                                                                    color: colorThemeTextRGB
                                                                                }}
                                                                                onClick={() => {
                                                                                    setFilteredOrgConsigneeAddresses(orgConsigneeAddresses)
                                                                                    handleConsigneeModalOpen()
                                                                                }}
                                                                                variant="contained"
                                                                                color="primary">
                                                                                <AddCircleIcon />
                                                                                Select Address
                                                                            </IconButton>
                                                                        }
                                                                        label=''
                                                                    />             
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: colorThemePrimaryRGB
                                                    }}></div>
                                                <Accordion
                                                    expanded={expandedPanels.includes('panel6')}
                                                    onChange={handleACCChange(
                                                        'panel6'
                                                    )}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: colorThemePrimaryRGB,
                                                                    color: colorThemePrimaryRGB
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }>
                                                            {' '}
                                                            <h5
                                                                style={{
                                                                    color: colorThemePrimaryRGB
                                                                }}>
                                                                {' '}
                                                                {renderTitle(
                                                                    'Details'
                                                                )}
                                                            </h5>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={3}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderSelect(
                                                                        'Transport Mode',
                                                                        'transportModeCode',
                                                                        transportModes,
                                                                        'propertyKey',
                                                                        'value',
                                                                        true
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderSelect(
                                                                        'Container Type Module', 
                                                                        'containerTypeCode',
                                                                        containerModes,
                                                                        'propertyKey',
                                                                        'value',
                                                                        true
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderSelect(
                                                                        'Inco Terms',
                                                                        'incoTermsCode',
                                                                        incoTerms,
                                                                        'propertyKey',
                                                                        'value',
                                                                        true
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderSelect(
                                                                        'Service Level',
                                                                        'serviceLevelCode',
                                                                        serviceLevels,
                                                                        'propertyKey',
                                                                        'value',
                                                                        true
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderSelect(
                                                                        'Commodity',
                                                                        'commodityCode',
                                                                        commodities,
                                                                        'propertyKey',
                                                                        'value',
                                                                        true
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderInput(
                                                                        'Goods Description',
                                                                        'goodsDescription'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={6}
                                                                    lg={6}
                                                                    md={6}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderUnitSection(
                                                                        'piecesCount',
                                                                        'piecesUOM',
                                                                        pieceUnits
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={6}
                                                                    lg={6}
                                                                    md={6}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderUnitSection(
                                                                        'weight',
                                                                        'weightUOM',
                                                                        weightUnits
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={6}
                                                                    lg={6}
                                                                    md={6}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    {renderUnitSection(
                                                                        'volume',
                                                                        'volumeUOM',
                                                                        volumeUnits
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    <div class="auto-complete">
                                                                        {renderAutocomplete(
                                                                            originUnlocoCodes,
                                                                            originUNLOCOCodeObject,
                                                                            'Origin',
                                                                            'originUNLOCOCode',
                                                                            (
                                                                                e,
                                                                                value
                                                                            ) => {
                                                                                if (
                                                                                    value !==
                                                                                    null
                                                                                ) {
                                                                                    setOriginUNLOCOCodeObject(
                                                                                        value
                                                                                    )
                                                                                }
                                                                            },
                                                                            e => {
                                                                                const value =
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                searchUNLOCO(
                                                                                    value,
                                                                                    true,
                                                                                    true
                                                                                )
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={3}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mb-2 xxl-2">
                                                                    <div class="auto-complete">
                                                                        {renderAutocomplete(
                                                                            destinationUnlocoCodes,
                                                                            destinationUNLOCOCodeObject,
                                                                            'Destination',
                                                                            'destinationUNLOCOCode',
                                                                            (
                                                                                e,
                                                                                value
                                                                            ) => {
                                                                                if (
                                                                                    value !==
                                                                                    null
                                                                                ) {
                                                                                    setDestinationUNLOCOCodeObject(
                                                                                        value
                                                                                    )
                                                                                }
                                                                            },
                                                                            e => {
                                                                                const value =
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                searchUNLOCO(
                                                                                    value,
                                                                                    false,
                                                                                    true
                                                                                )
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: colorThemePrimaryRGB
                                                    }}></div>
                                                <Accordion
                                                    expanded={expandedPanels.includes('panel7')}
                                                    onChange={handleACCChange(
                                                        'panel7'
                                                    )}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: colorThemePrimaryRGB,
                                                                    color: colorThemePrimaryRGB
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }>
                                                            {' '}
                                                            <h5
                                                                style={{
                                                                    color: colorThemePrimaryRGB
                                                                }}>
                                                                {' '}
                                                                {renderTitle(
                                                                    'Dates'
                                                                )}
                                                            </h5>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            {renderDateSelectors(organization)}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: colorThemePrimaryRGB
                                                    }}>
                                                </div>
                                                <Accordion
                                                    square
                                                    className="w-100"
                                                    expanded={expandedPanels.includes('panel8')}
                                                    onChange={handleACCChange(
                                                        'panel8'
                                                    )}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: colorThemePrimaryRGB,
                                                                    color: colorThemePrimaryRGB
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }>
                                                            {' '}
                                                            <h5
                                                                style={{
                                                                    color: colorThemePrimaryRGB
                                                                }}>
                                                                {renderTitle(
                                                                    'Linked Order Details'
                                                                )}
                                                            </h5>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-2 mb-4">
                                                                <Grid item xs={12}>
                                                                    <div className="psa-shipment-table  w-100">
                                                                        {renderTable(
                                                                            bookingOrders,
                                                                            linkedOrderHeaderColumns,
                                                                            'linkedOrders',
                                                                            classes,
                                                                            null,
                                                                            {
                                                                                maxHeight: '350px'
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: colorThemePrimaryRGB
                                                    }}></div>
                                                <div class="spacer mb-40px w-100"></div>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12}>
                                                        <div className="w-100 mb-4 d-flex justify-content-end">
                                                            {renderBookingAlert()}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <div className="w-100 mb-4 d-flex justify-content-end">
                                                            <Button
                                                                style={{
                                                                    background: colorThemePrimaryRGB,
                                                                    border: colorThemePrimaryRGB,
                                                                    color: colorThemeTextRGB
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                type='submit'>
                                                                Save/Next
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6} />
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} />
                                    </Grid>
                                </div>
                            </Grid>
                        </form> 
                        )
                    }
                </Paper>
            </CardContent>
            <CustomDialog
                isOpen={modalOpen}
                handleClose={handleModalClose}
                title=""
                //className="overflow-y-hidden"
                style={{
                    border: colorThemePrimaryRGB
                }}>
                <h4
                    className="px-4 pb-3"
                    style={{
                        color: colorThemePrimaryRGB
                    }}>
                    {isConsignor ? 'Select Consignee' : 'Select Consignor'}
                </h4>
                <TextField
                    style={{ width: '80%' }}
                    onChange={(e) => {
                        // need to filter the addresses based on the input
                        const value = e.target.value
                        const filteredAddresses = addresses.filter(address => {
                            return (
                                address.orgCode
                                    .toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                address.name
                                    .toLowerCase()
                                    .includes(value.toLowerCase())
                            )
                        })
                        setFilteredAddresses(filteredAddresses)
                    }}
                    placeholder={'Enter org code or name..'}
                />
                <div
                    class="separator"
                    style={{
                        background: colorThemePrimaryRGB
                    }}></div>
                <>
                    {
                        // if addresses is empty then display 'no results'
                        addresses.length === 0 && (
                            <div class="modaldialog-content">
                                <p>No records found!</p>
                            </div>
                        )
                    }
                    <List>
                        {filteredAddresses.map((address, i) => (
                            <ListItem
                                button
                                onClick={() => {
                                    updateAddressInfo(address)
                                    setModalOpen(false)
                                }}
                                key={`${i}_${address.orgCode}`}>
                                {`${address.orgCode} | ${address.name} | ${address.city + ', ' + address.addressLine1.substring(0,15) + '...'} `}
                            </ListItem>
                        ))}
                    </List>
                </>
            </CustomDialog>
            <CustomDialog
                isOpen={consigneeAddressesModalOpen}
                handleClose={handleConsigneeAddressModalClose}
                title=""
                //className="overflow-y-hidden"
                style={{
                    border: colorThemePrimaryRGB
                }}>
                <h4
                    className="px-4 pb-3"
                    style={{
                        color: colorThemePrimaryRGB
                    }}>
                    Select Address
                </h4>
                <TextField
                    style={{ width: '80%' }}
                    onChange={(e) => {
                        // need to filter the addresses based on the input
                        const value = e.target.value
                        const filteredConsigneeAddresses = orgConsigneeAddresses.filter(address => {
                            return (
                                address.orgCode
                                    .toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                address.name
                                    .toLowerCase()
                                    .includes(value.toLowerCase())
                            )
                        })
                        setFilteredOrgConsigneeAddresses(filteredConsigneeAddresses)
                    }}
                    placeholder={'Enter org code or name..'}
                />
                <div
                    class="separator"
                    style={{
                        background: colorThemePrimaryRGB
                    }}></div>
                <>
                    {
                        // if addresses is empty then display 'no results'
                        orgConsigneeAddresses.length === 0 && (
                            <div class="modaldialog-content">
                                <p>No records found!</p>
                            </div>
                        )
                    }
                    <List>
                        {filteredOrgConsigneeAddresses.map((address, i) => (
                            <ListItem
                                button
                                onClick={() => {
                                    updateAddressInfoFromSelection(address, 'consignee')
                                    setConsigneeAddressesModalOpen(false)
                                }}
                                // set bold if isprimary = true
                                style={{
                                    fontWeight: address.isPrimary ? 'bold' : 'normal'
                                }}
                                key={`${i}_${address.orgCode}`}>
                                {`${address.orgCode} | ${address.name} | ${address.city + ', ' + address.addressLine1.substring(0,15) + '...'} `}
                            </ListItem>
                        ))}
                    </List>
                </>
            </CustomDialog>
            <CustomDialog
                isOpen={consignorAddressesModalOpen}
                handleClose={handleConsignorAddressModalClose}
                title=""
                //className="overflow-y-hidden"
                style={{
                    border: colorThemePrimaryRGB
                }}>
                <h4
                    className="px-4 pb-3"
                    style={{
                        color: colorThemePrimaryRGB
                    }}>
                    Select Address
                </h4>
                <TextField
                    style={{ width: '80%' }}
                    onChange={(e) => {
                        // need to filter the addresses based on the input
                        const value = e.target.value
                        const filteredConsignorAddresses = orgConsignorAddresses.filter(address => {
                            return (
                                address.orgCode
                                    .toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                address.name
                                    .toLowerCase()
                                    .includes(value.toLowerCase())
                            )
                        })
                        setFilteredOrgConsignorAddresses(filteredConsignorAddresses)
                    }}
                    placeholder={'Enter org code or name..'}
                />
                <div
                    class="separator"
                    style={{
                        background: colorThemePrimaryRGB
                    }}></div>
                <>
                    {
                        // if addresses is empty then display 'no results'
                        orgConsignorAddresses.length === 0 && (
                            <div class="modaldialog-content">
                                <p>No records found!</p>
                            </div>
                        )
                    }
                    <List>
                        {filteredOrgConsignorAddresses.map((address, i) => (
                            <ListItem
                                button
                                onClick={() => {
                                    updateAddressInfoFromSelection(address, 'consignor')
                                    setConsignorAddressesModalOpen(false)
                                }}
                                // set bold if isprimary = true
                                style={{
                                    fontWeight: address.isPrimary ? 'bold' : 'normal'
                                }}
                                key={`${i}_${address.orgCode}`}>
                                {`${address.orgCode} | ${address.name} | ${address.city + ', ' + address.addressLine1.substring(0,15) + '...'} `}
                            </ListItem>
                        ))}
                    </List>
                </>
            </CustomDialog>
        </div>
    )
}
