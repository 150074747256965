import FlightIcon from '@material-ui/icons/Flight'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import { Polyline, OverlayView } from '@react-google-maps/api'
import React from 'react'
import { PortableWifiOff } from '@material-ui/icons'

var lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 4
}

const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2)
})

const checkValidPosition = position => {
    return (
        position !== null &&
        position !== '' &&
        !isNaN(position?.lat) &&
        !isNaN(position?.lng)
    )
}

export function renderMarkers(
    index,
    shipment,
    shipmentExtra,
    origin,
    destination,
    portOfLoading,
    portOfDischarge,
    currentLocation = null
) {
    var array = []

    if (checkValidPosition(origin)) {
        array.push(origin)
    }

    if (checkValidPosition(portOfLoading)) {
        array.push(portOfLoading)
    }

    if (checkValidPosition(currentLocation)) {
        array.push(currentLocation)
    }

    if (checkValidPosition(portOfDischarge)) {
        array.push(portOfDischarge)
    }

    if (checkValidPosition(destination)) {
        array.push(destination)
    }

    // remove any duplicate coordinates
    array = array.filter(
        (v, i, a) => a.findIndex(t => t.lat === v.lat && t.lng === v.lng) === i
    )

    const options = {
        fillColor: '#757575',
        fillOpacity: 0,
        strokeColor: '#757575',
        strokeOpacity: 0,
        paths: 'M 0,-1 0,1',
        scale: 4,
        path: array,
        zIndex: 1,
        geodesic: (shipmentExtra?.openTrackCoordinates?.length > 0 ? false : true),
        icons: [
            {
                icon: lineSymbol,
                offset: '0',
                repeat: '20px'
            }
        ]
    }

    if (
        (checkValidPosition(origin) || checkValidPosition(portOfLoading)) &&
        (checkValidPosition(destination) || checkValidPosition(portOfDischarge))
    ) {
        if (currentLocation == null) {
            return (
                <div key={index}>
                    <Polyline path={array} options={{ options }} />
                    <OverlayView
                        position={
                            checkValidPosition(origin) ? origin : portOfLoading
                        }
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={getPixelPositionOffset}>
                        {getIconForMap(shipment, true)}
                    </OverlayView>

                    <OverlayView
                        position={
                            checkValidPosition(destination)
                                ? destination
                                : portOfDischarge
                        }
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={getPixelPositionOffset}>
                        {getIconForMap(shipment, false)}
                    </OverlayView>
                </div>
            )
        } else {
            return (
                <div key={index}>
                    <Polyline path={array} options={{ options }} />
                    <OverlayView
                        position={
                            checkValidPosition(origin) ? origin : portOfLoading
                        }
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={getPixelPositionOffset}>
                        {getIconForMap(shipment, true)}
                    </OverlayView>

                    <OverlayView
                        position={currentLocation}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={getPixelPositionOffset}>
                        {getIconForShipment(shipment)}
                    </OverlayView>

                    <OverlayView
                        position={
                            checkValidPosition(destination)
                                ? destination
                                : portOfDischarge
                        }
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={getPixelPositionOffset}>
                        {getIconForMap(shipment, false)}
                    </OverlayView>
                </div>
            )
        }
    } else {
        if (checkValidPosition(currentLocation)) {
            return (
                <div key={index}>
                    <OverlayView
                        position={currentLocation}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={getPixelPositionOffset}>
                        {getIconForShipment(shipment)}
                    </OverlayView>
                </div>
            )
        }
        return <div key={index}>&nbsp;</div>
    }
}

export function getLatLng(shipment, key) {
    if (!shipment) {
        return null
    }

    const locationGeoCoordinates = shipment[key]
    if (!locationGeoCoordinates) {
        return null
    }
    
    const array = locationGeoCoordinates.split(',')
    return { lat: parseFloat(array[0]), lng: parseFloat(array[1]) }
}

export function getIconForMap(shipment, isOrigin) {
    switch (shipment.mode) {
        case 'SEA':
            return isOrigin ? (
                <FiberManualRecordIcon style={{ color: 'green' }} />
            ) : (
                <FiberManualRecordIcon style={{ color: 'red' }} />
            )
            break

        case 'AIR':
            return isOrigin ? (
                <FiberManualRecordIcon style={{ color: 'green' }} />
            ) : (
                <FiberManualRecordIcon style={{ color: 'red' }} />
            )
            break

        case 'COU':
            return isOrigin ? (
                <FiberManualRecordIcon style={{ color: 'green' }} />
            ) : (
                <FiberManualRecordIcon style={{ color: 'red' }} />
            )
            break

        case 'ROA':
            return isOrigin ? (
                <FiberManualRecordIcon style={{ color: 'green' }} />
            ) : (
                <FiberManualRecordIcon style={{ color: 'red' }} />
            )
            break

        default:
            return isOrigin ? (
                <FiberManualRecordIcon style={{ color: 'green' }} />
            ) : (
                <FiberManualRecordIcon style={{ color: 'red' }} />
            )
    }
}

export function getIconForShipment(shipment) {
    if (shipment.transportMode) {
        if (shipment.transportMode == 'AIR') {
            return <FlightIcon color="action" />
        } else if (shipment.transportMode == 'ROA') {
            return <LocalShippingIcon color="action" />
        } else {
            return <DirectionsBoatIcon color="action" />
        }
    } else {
        if (shipment.mode == 'AIR') {
            return <FlightIcon color="action" />
        } else if (shipment.mode == 'ROA') {
            return <LocalShippingIcon color="action" />
        } else {
            return <DirectionsBoatIcon color="action" />
        }
    }
}
