import '../custom.css'
import React, { Component } from 'react'

import authService from './api-authorization/AuthorizeService'

import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import MUIDataTable from 'mui-datatables'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    link: {
        color: 'blue'
    }
})

class DashboardsMore extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: []
        }
    }

    componentDidMount() {
        this.populateDashboardsData()
    }

    async populateDashboardsData() {
        const user = await authService.getUser()
        let userId = ''
        if (user) {
            userId = user.sub
        } else {
            userId = null
        }
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/users/GetUserBIDashboards/' + userId,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            this.setState({
                items: data
            })
        }
    }

    populateDataToDisplay() {
        const { items } = this.state
        if (items) {
            var DashboardArray = []
            for (let i = 0; i < items.length; i++) {
                let dashboardArray = []
                dashboardArray.push(items[i].dashboardName)
                dashboardArray.push(items[i].dashboardId)
                dashboardArray.push(items[i].groupId)

                DashboardArray.push(dashboardArray)
            }
            return DashboardArray
        }
    }
    render() {
        const { classes } = this.props

        const columns = [
            {
                id: 'dashboardid',
                label: 'DashboardId',
                options: { filter: false, sort: false, display: 'none' }
            },
            {
                id: 'groupid',
                label: 'GroupId',
                options: { filter: false, sort: false, display: 'none' }
            },
            {
                name: 'dashboardname',
                label: 'Name',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var customerId = tableMeta.rowData[0]
                        var dashboardid = tableMeta.rowData[1]
                        var groupid = tableMeta.rowData[2]
                        var path = 'Dashboard/' + dashboardid + '/' + groupid
                        return (
                            <div>
                                <a className={classes.link} href={path}>
                                    {customerId}
                                </a>
                            </div>
                        )
                    }
                }
            }
        ]

        const data = this.populateDataToDisplay()

        const options = {
            filter: false,
            viewColumns: false,
            print: false,
            download: false,
            selectableRows: 'none',
            caseSensitive: false,
            pagination: false,
            searchOpen: true,
            searchIcon: false
        }
        return (
            <div>
                <h1 class="mb-2">Dashboards</h1>
                <div class="psa-shipment-table dashboard pb-3 clients">
                    <MUIDataTable
                        className="pb-3"
                        // title={'Dashboards'}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(DashboardsMore)
