import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import { Checkbox, Typography } from '@material-ui/core'
import { FormControlLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    title: {
        // backgroundColor: theme.palette.primary.main,
        // color: theme.palette.primary.contrastText

        color: theme.palette.primary.main
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    }
})
class CardTitle extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {
            classes,
            title,
            itemLabel,
            disabledOn,
            searchOn,
            checkOn,
            disablePadding
        } = this.props

        return (
            <Card
                id={'title_' + searchOn ? 'Content' : 'Module'}
                className="w-100">
                <CardHeader
                    className={classes.title}
                    title={
                        <Typography gutterBottom variant="h6">
                            {title}
                        </Typography>
                    }
                />
                <CardContent className={disablePadding ? 'p-0' : ''}>
                    {!checkOn ? (
                        this.props.children
                    ) : (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="search"
                                    disabled={disabledOn}
                                    checked={searchOn}
                                    onChange={this.props.callback}
                                />
                            }
                            label={itemLabel}
                        />
                    )}
                </CardContent>
            </Card>
        )
    }
}
CardTitle.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    checkOn: PropTypes.bool.isRequired,
    itemLabel: PropTypes.string,
    disabledOn: PropTypes.bool,
    searchOn: PropTypes.bool,
    callback: PropTypes.func,
    disablePadding: PropTypes.bool
}
export default withStyles(styles)(CardTitle)
