import React, { Component, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import { TextField, Tooltip, LinearProgress } from '@material-ui/core'
import { FormControl, InputLabel } from '@material-ui/core'
import { MenuItem, Select, Grid, Button, Link } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Alert from '@material-ui/lab/Alert'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { renderMarkers, getLatLng } from '../components/shared/MapHelper'
import { getIconForShipment } from '../components/shared/MapHelper'
import DateFnsUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'
import { CardContent } from '@material-ui/core'
import SummaryInfo from './shared/SummaryInfo'
import MaterialTable from 'material-table'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import { Paper } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import FlagIcon from '@material-ui/icons/Flag'
import localforage from 'localforage'
import { sortData } from '../utils/sorting'
import { ThemeProvider, createTheme } from '@mui/material'
import TableViewManagementDialog from './shared/TableViewManagementDialog'
import { retrieveView, getViewCodeBySearchItem, getViewNameBySearchItem, saveViewColumns } from './shared/Views'
import TableViewCreateDialog from './shared/TableViewCreateDialog'
import DialogContainer from './DialogContainer'
import { getContainerExtraData } from './shared/ShipmentsHelper'
import { prepareCsvExport } from '../utils/exporting'


const styles = theme => ({
    formControl: {
        margin: 0,
        minWidth: '170px',
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap'
    },
    link: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
})

const mapContainerStyle = {
    height: '100%',
    width: '100%'
}

const dateFns = new DateFnsUtils()

class ContainerTable extends Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
        this.state = {
            items: [],
            containerList: [],
            clients: [],
            clientId: null,
            organization: null,
            selectedDate: 2,
            toDate: dateFns.moment().weekday(6),
            fromDate: dateFns.moment().weekday(0),
            minDate: dateFns.moment().weekday(0),
            todayDate: new Date(),
            loading: false,
            error: false,
            rowsPerPage: 50,
            page: 0,
            order: 'asc',
            orderBy: 'shipmentNumber',
            resultsBox1Value: 0,
            resultsBox1Title: '',
            resultsBox2Value: 0,
            resultsBox2Title: '',
            resultsBox3Value: 0,
            resultsBox3Title: '',
            resultsBox4Value: 0,
            resultsBox4Title: '',
            resultsBox5Value: 0,
            resultsBox5Title: '',
            lastCompletedMilestone: 'ALL',
            referenceType: 'CONTAINER#',
            dateView: 'ETA',
            itemReference: null,
            mode: 'ALL',
            theme: props.theme,
            searchItem: props.searchItem || 'Container Management',
            titleIcon: props.titleIcon || 'border_all',
            key: props.itemKey || 'container',
            originUNLOCOCodeObject: null,
            originName: null,
            originCountryCodeObject: null,
            destinationUNLOCOCodeObject: null,
            destinationName: null,
            destinationCountryCodeObject: null,
            originUnlocoCodes: [],
            originCountryCodes: [],
            destinationUnlocoCodes: [],
            destinationCountryCodes: [],
            downloadCSVDisabled: false,
            displayAdvancedSearchProperties: false,
            searching: false,
            containerFilters: [],
            orgHeaderColumns: [],
            containerSearchTypes: [],
            userViewId: null,
            primaryColor: '#ffffff',
            themeTextColor: '#000000',
            selectedContainer: null,
            showContainerDialog: false,
            currentSummaryTile: 1,
            dateFiltering: []
        }
    }

    async componentDidMount() {
        this.setState({ loading: false })
        var referredFromDetailPage = await JSON.parse(
            localStorage.getItem('referredFromDetailPage')
        )

        if (referredFromDetailPage) {
            await this.loadAndSetFromLocalForage('organization')
            await this.loadAndSetFromLocalForage('clients')
        } else {
            await this.populateConfig()
            await this.populateClientsData()
        }
        
        await this.loadAndSetFromLocalStorage('clientId')
        await this.fetchGridSearchTypes()
        await this.initSearch()
        await this.getOrgHeaderColumns()

        if (!referredFromDetailPage) {
            await this.populateTableData(true, true)
        }

        this.setState({ loading: false })
    }

    async initSearch() {
        // only load search from cache if navigating back from detail page
        var referredFromDetailPage = await JSON.parse(
            localStorage.getItem('referredFromDetailPage')
        )

        if (referredFromDetailPage) {
            await this.loadAndSetFromLocalForageNoKey('containerFilters')
            await this.loadAndSetFromLocalStorage('resultsBox1Value')
            await this.loadAndSetFromLocalStorage('resultsBox1Title')
            await this.loadAndSetFromLocalStorage('resultsBox2Value')
            await this.loadAndSetFromLocalStorage('resultsBox2Title')
            await this.loadAndSetFromLocalStorage('resultsBox3Value')
            await this.loadAndSetFromLocalStorage('resultsBox3Title')
            await this.loadAndSetFromLocalStorage('resultsBox4Value')
            await this.loadAndSetFromLocalStorage('resultsBox4Title')
            await this.loadAndSetFromLocalStorage('resultsBox5Value')
            await this.loadAndSetFromLocalStorage('resultsBox5Title')
            await this.loadAndSetFromLocalForage('containerList')
            await this.loadAndSetFromLocalForage('items')
            await this.loadAndSetFromLocalForage('currentSummaryTile')
            // reset 'referred' value in local storage
            localStorage.setItem(
                'referredFromDetailPage',
                JSON.stringify(false)
            )
        } else {
            // clear shipmentfilters?
        }

        await this.loadAndSetFromLocalStorage('itemReference')
        await this.loadAndSetFromLocalStorage('mode')
        await this.loadAndSetFromLocalStorage('referenceType')
        await this.loadAndSetFromLocalStorage('dateView')
        await this.loadAndSetFromLocalStorage('selectedDate')
        await this.loadAndSetFromLocalStorage('fromDate')
        await this.loadAndSetFromLocalStorage('toDate')

        const { selectedDate } = this.state
        this.setDatesFromDateDropdown(selectedDate)

        // check if referenceType exists in the containerSearchTypes array
        const { containerSearchTypes, referenceType } = this.state
        const referenceTypeExists = containerSearchTypes.find(
            e => e.searchType === referenceType
        )
        // if it doesn't exist, set the first searchType in the array
        if (!referenceTypeExists && containerSearchTypes.length > 0) {
            this.setState({ referenceType: containerSearchTypes[0].searchType })
        }
    }

    async loadAndSetFromLocalStorage(keyName, jsonParse = false) {
        const { key } = this.state
        const localStorageKey = `${key}.${keyName}`
        const value = jsonParse
            ? await JSON.parse(localStorage.getItem(localStorageKey))
            : await localStorage.getItem(localStorageKey)

        if (value !== null) {
            this.setState({ [`${keyName}`]: value })
        }
    }

    async loadAndSetFromLocalForage(keyName) {
        const { key } = this.state
        const localForageKey = `${key}.${keyName}`
        const value = await localforage.getItem(localForageKey)
        if (value !== null) {
            this.setState({ [`${keyName}`]: value })
        }
    }

    async loadAndSetFromLocalForageNoKey(keyName) {
        const value = await localforage.getItem(keyName)
        if (value !== null) {
            this.setState({ [`${keyName}`]: value })
        }
    }

    getOrgHeaderColumns = async () => {
        const { clientId } = this.state
        if (clientId) {
            const view = await retrieveView(clientId, 'ContainerSearchGrid')
            if (view) {
                this.setState({ 
                    orgHeaderColumns: view.columnDefinitionsArray, 
                    userViewId: view.id
                })
            }
        }
    }

    handleContainerNumberClick = async (rowData) => {
        // first need to get container data
        const { clientId } = this.state
        this.setState({ searching: true })

        const container = await getContainerExtraData(rowData, rowData.containerNumber, clientId)

        if (container) {
            this.setState({
                selectedContainer: container
            }, () => {
                this.setState({ showContainerDialog: true })
            })
            this.setState({ searching: false })
        }
        else {
            this.setState({ searching: false })
        }
    }

    setContainerDialogState = (showDialog) => {
        this.setState({ showContainerDialog: showDialog })
    }

    saveContainerFilterValuesToState = tableRef => {
        const newColumnFilters = tableRef.current.state.columns.map(column => ({
            field: column.field,
            filterValue: column.tableData.filterValue
        }))
        localforage.setItem('containerFilters', newColumnFilters)
        this.setState({ containerFilters: newColumnFilters })
    }

    getURLForItem = itemvalue => {
        const { clientId, key } = this.state

        var initialPath = `${ApplicationPaths.Shipments}`
        if (key === 'customs') {
            initialPath = `${ApplicationPaths.Customs}`
        } else if (key === 'booking') {
            initialPath = `${ApplicationPaths.Bookings}`
        }

        return {
            pathname: initialPath + '/' + clientId + '/' + itemvalue
        }
    }

    handleSubmit = async event => {
        this.saveContainerFilterValuesToState(this.tableRef)
        this.populateTableData(event)
    }

    handleClearAll = async event => {
        for (let [key, value] of Object.entries(localStorage)) {
            if (key.indexOf(`${this.state.key}.`) !== -1) {
                localStorage.removeItem(key)
            }
        }

        localStorage.removeItem('referredFromDetailPage')

        var lfKeys = []

        await localforage
            .keys()
            .then(function(keys) {
                lfKeys = keys
            })
            .catch(function(err) {
                console.log(err)
            })

        for (let lfKey of lfKeys) {
            if (lfKey.indexOf(this.state.key + '.') !== -1) {
                await localforage
                    .removeItem(lfKey)
                    .then(function() {
                        // nothing
                    })
                    .catch(function(err) {
                        console.log(err)
                    })
            }
        }

        window.location.reload()
    }

    setDatesFromDateDropdown = value => {
        let from = null
        let to = null
        const { key, fromDate, toDate } = this.state

        switch (parseInt(value)) {
            case 0: // Today
                from = dateFns.moment().startOf('day')
                to = dateFns.moment().endOf('day')
                break
            case 1: // This Week
                from = dateFns.moment().startOf('week')
                to = dateFns.moment().endOf('week')
                break
            case 2: // This Month
                from = dateFns.moment.utc().startOf('month')
                to = dateFns.moment.utc().endOf('month')
                break
            case 3: // This Year
                from = dateFns.moment.utc().startOf('year')
                to = dateFns.moment.utc().endOf('year')
                break
            case 4: // Next Week
                let nwDate = dateFns.moment.utc(new Date()).add(1, 'week')
                from = dateFns.moment(nwDate).startOf('week')
                to = dateFns.moment(nwDate).endOf('week')
                break
            case 5: // Next Month
                let nmDate = dateFns.moment.utc(new Date()).add(1, 'month')
                from = dateFns.moment(nmDate).startOf('month')
                to = dateFns.moment(nmDate).endOf('month')
                break
            case 6: // Next Year
                let nyDate = dateFns.moment.utc(new Date()).add(1, 'year')
                from = dateFns.moment(nyDate).startOf('year')
                to = dateFns.moment(nyDate).endOf('year')
                break
            case 7: // Last Week
                let lwDate = dateFns.moment.utc(new Date()).subtract(1, 'week')
                from = dateFns.moment(lwDate).startOf('week')
                to = dateFns.moment(lwDate).endOf('week')
                break
            case 8: // Last Month
                let lmDate = dateFns.moment.utc(new Date()).subtract(1, 'month')
                from = dateFns.moment(lmDate).startOf('month')
                to = dateFns.moment(lmDate).endOf('month')
                break
            case 9: // Last Year
                let lyDate = dateFns.moment.utc(new Date()).subtract(1, 'year')
                from = dateFns.moment(lyDate).startOf('year')
                to = dateFns.moment(lyDate).endOf('year')
                break
            case 10: // All Time
                break
            case 11: // Custom
                from = dateFns.moment(fromDate)
                to = dateFns.moment(toDate)
                break
            default:
                break
        }

        localStorage.setItem(key + '.fromDate', from)
        localStorage.setItem(key + '.toDate', to)

        this.setState({
            selectedDate: value,
            fromDate: from,
            toDate: to
        })
    }

    handleChangeValue = (event, elem) => {
        let value = null
        const { key } = this.state

        const customIndex = 11

        switch (elem) {
            case 'fromDate':
                this.setState({ fromDate: event, selectedDate: customIndex })
                localStorage.setItem(key + '.selectedDate', customIndex)
                localStorage.setItem(key + '.fromDate', event)
                break

            case 'toDate':
                this.setState({ toDate: event, selectedDate: customIndex })
                localStorage.setItem(key + '.selectedDate', customIndex)
                localStorage.setItem(key + '.toDate', event)
                break

            case 'selectedDate':
                value = event.target.value
                localStorage.setItem(key + '.selectedDate', value)
                this.setDatesFromDateDropdown(value)
                break

            case 'dateView':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.dateView', value)
                this.setState({
                    dateView: value
                })
                break

            case 'reference-type':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.referenceType', value)
                this.setState({
                    referenceType: value
                })
                break

            case 'client':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.clientId', value)
                this.setState({
                    clientId: value
                })
                break

            case 'mode':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.mode', value)
                this.setState({
                    mode: value
                })
                break

            case 'reference':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.itemReference', value)
                this.setState({
                    itemReference: value
                })
                break

            default:
                break
        }
    }

    getDateObject = (time, substract = false) => {
        var moment = require('moment')
        const format = 'YYYY-MM-DD HH:mm:ss'
        var base = substract ? moment().substract(1, 'month') : moment()
        return {
            From: base.startOf(time).format(format),
            To: base.endOf(time).format(format)
        }
    }

    getDates = () => {
        const { selectedDate } = this.state

        if (selectedDate === 0) {
            return this.getDateObject('day')
        } else if (selectedDate === 1) {
            return this.getDateObject('week')
        } else if (selectedDate === 2) {
            return this.getDateObject('month')
        } else if (selectedDate === 3) {
            return this.getDateObject('month', true)
        } else if (selectedDate === 4) {
            return this.getDateObject('year')
        }

        return this.getDateObject('day')
    }

    renderToolTipOrderRef = orderRefValue => {
        var orderRef = ''
        var array = []
        if (orderRefValue != null) {
            array = orderRefValue.split(',')
            if (array.length <= 2) {
                orderRef = array.join()
            } else {
                orderRef = array[0]
            }
        }

        return (
            <p>
                {orderRef}
                {array.length > 2 ? (
                    <Tooltip
                        title={
                            <>
                                <h6>{array.join()}</h6>
                            </>
                        }
                        placement="right">
                        <MoreHorizIcon />
                    </Tooltip>
                ) : (
                    ''
                )}
            </p>
        )
    }

    renderNavLinkOrderRef = (clientId, containerData) => {
        var orderRefs = containerData.orderReferenceNumber
        if (orderRefs != null) {
            return (
                <>
                    {orderRefs.split(',').map((refnum, index) => {
                        if (containerData.orders) {
                            var order = containerData.orders.find(
                                order => order.orderNumber === refnum.trim()
                            )
                            if (order) {
                                return (
                                    <div>
                                        <NavLink
                                            onClick={() => {
                                                this.saveContainerFilterValuesToState(
                                                    this.tableRef
                                                )
                                            }}
                                            key={index}
                                            to={
                                                '/orders/' +
                                                clientId +
                                                '/' +
                                                btoa(refnum.trim()).replace(
                                                    '/',
                                                    '_'
                                                ) +
                                                '/' +
                                                order.orderNumberSplit
                                            }>
                                            {refnum}
                                        </NavLink>
                                    </div>
                                )
                            } else {
                                return <div>{refnum}</div>
                            }
                        } else {
                            return <div>{refnum}</div>
                        }
                    })}
                </>
            )
        } else {
            return <>-</>
        }
    }

    renderContainersTable = items => {
        const {
            organization,
            key,
            searchItem,
            clientId,
            clients,
            containerFilters,
            orgHeaderColumns,
            userViewId,
            themeTextColor,
            primaryColor
        } = this.state

        const currentClient = clients?.find(e => e.id === clientId)
        const tableTheme = createTheme()

        let cachedHeaderKeyValue = ''
        cachedHeaderKeyValue =
            currentClient?.clientView !== 'DEFAULT'
                ? 'columnHeaders'.concat('_', currentClient?.clientView)
                : 'columnHeaders'

        const extraCells =
            key === 'booking'
                ? [
                      {
                          title: 'Booking Date',
                          value: 'bookingDate',
                          visible: true
                      }
                  ]
                : []

        // Have to map new property as column header doesn't match field value
        const mappedItems = items.map(item => ({
            ...item,
            transportMode: item.mode ? item.mode : item.transportMode
        }))

        // Map headCells to match required properties for material-table
        function getHeaderColumns() {
            const columnHeaders =
                organization !== null && orgHeaderColumns !== null
                    ? orgHeaderColumns
                          .concat(extraCells)
                          .map(headcell => {
                              return {
                                  field: headcell.value, 
                                  title: headcell.title,
                                  type: headcell.type,
                                  sortField: headcell.sortField,
                                  hidden: headcell.visible ? false : true,
                                  cellStyle: {
                                      whiteSpace: 'nowrap'
                                  },
                                  removable: true,
                                  hiddenByColumnsButton: true,
                                  id: headcell.id,
                                  order: headcell.order,
                                  customTitle: headcell.customTitle,
                                  width: headcell.width
                              }
                          })
                    : []
            return columnHeaders
        }

        let savedColumns = getHeaderColumns()

        // Need to add the render function as can't store this in localstorage
        var columnHeaders = savedColumns.map(column => {
            return {
                field: column.field,
                title: column.title,
                type: column.type,
                sortField: column.sortField,
                customSort: (a, b) => sortData(column, a, b),
                hidden: column.hidden,
                removable: column.removable,
                hiddenByColumnsButton: column.hiddenByColumnsButton,
                defaultFilter: getFilter(column.field),
                cellStyle: {
                    whiteSpace: 'nowrap'
                },
                render: params => {
                    if (column.field === 'transportMode') {
                        return getIconForShipment(params)
                    } else if (column.field === 'shipmentNumber') {
                        return (
                            <NavLink
                                onClick={() => {
                                    this.saveContainerFilterValuesToState(
                                        this.tableRef
                                    )
                                }}
                                to={this.getURLForItem(params.shipmentNumber)}>
                                {params.shipmentNumber}
                            </NavLink>
                        )
                    }  else if (column.field === 'containerNumber') {
                        return (
                            params.containerNumber ? 
                                <>
                                    <Link
                                        style={{ cursor: 'pointer' }}
                                        color="primary"
                                        onClick={() => {
                                            this.handleContainerNumberClick(params)
                                        }}>
                                        {params.containerNumber || ''}
                                    </Link>
                                </>
                            : ''
                        )
                    } else if (column.field === 'orderReferenceNumber') {
                        return this.renderNavLinkOrderRef(clientId, params)
                    } else if (column.field === 'shipmentFlagged') {
                        return params.shipmentFlagged ? 'YES' : null
                    } else if (column.field === 'orderRef') {
                        return this.renderToolTipOrderRef(params.orderRef)
                    } else if (
                        organization?.containerTrackingURL !== null &&
                        column.field === 'containerNumber' &&
                        params.containerNumber !== '' &&
                        params.containerNumber !== null
                    ) {
                        return params.containerNumber.split(',').map(c => {
                            return (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        const newWindow = window.open(
                                            organization?.containerTrackingURL.replace(
                                                '{CONTAINERNO}',
                                                c
                                            ),
                                            '_blank',
                                            'noopener,noreferrer'
                                        )
                                        if (newWindow) newWindow.opener = null
                                    }}>
                                    {c}
                                </Button>
                            )
                        })
                    } else {
                        return params[column.field]
                    }
                }
            }
        })

        const handleColumnDrag = (sourceIndex, destinationIndex) => {
            // take copy of source column
            const sourceColumn = savedColumns[sourceIndex]
            // remove source column
            savedColumns.splice(sourceIndex, 1)
            // insert at destination
            savedColumns.splice(destinationIndex, 0, sourceColumn)          
            // update database   
            const viewCode = getViewCodeBySearchItem(searchItem)
            // map savedColumns from frontend to match the database
            savedColumns = savedColumns.map(column => {
                return {
                    id: column.id,
                    title: column.title,
                    customTitle: column.customTitle,
                    value: column.field, 
                    width: column.width,
                    visible: column.hidden ? false : true,  
                    order: column.order,
                    type: column.type,
                    sortField: column.sortField
                }
            })

            this.setState({ orgHeaderColumns: savedColumns })

            saveViewColumns(viewCode, savedColumns, userViewId, currentClient?.clientViewId, true, clientId)
        }

        function handleChangeColumnHidden(column, hidden) {
            // Update hidden flag
            for (let i = 0; i < savedColumns.length; i++) {
                if (savedColumns[i].field === column.field) {
                    // Set column hidden
                    savedColumns[i].hidden = hidden
                    localStorage.setItem(
                        cachedHeaderKeyValue,
                        JSON.stringify(savedColumns)
                    )
                    break
                }
            }
        }

        function getFilter(field) {
            const columnData = containerFilters.find(col => col.field === field)
            if (columnData) {
                return columnData.filterValue
            } else {
                return null
            }
        }

        return (
            <ThemeProvider theme={tableTheme}>
                <MaterialTable
                    tableRef={this.tableRef}
                    columns={columnHeaders}
                    data={mappedItems}
                    isLoading={this.state.searching}
                    icons={{
                        SortArrow: forwardRef((props, ref) => (
                            <ArrowDownward {...props} ref={ref} />
                        ))
                    }}
                    actions={[
                        {
                            icon: () => {
                                return (
                                    <TableViewManagementDialog 
                                        viewCode={'ContainerSearchGrid'}
                                        viewName={'Container Search'}
                                        fontColor={themeTextColor}
                                        backgroundColor={primaryColor}
                                        successCallback={this.getOrgHeaderColumns}
                                        clientId={clientId}
                                    />
                                )
                            },
                            isFreeAction: true,
                        },
                        {
                            icon: () => {
                                return (
                                    <TableViewCreateDialog 
                                        fontColor={themeTextColor}
                                        backgroundColor={primaryColor}
                                    />
                                )
                            },
                            isFreeAction: true,
                        }

                    ]}
                    options={{
                        filtering: true,
                        maxBodyHeight: 'calc(100vh - 470px)',
                        pageSizeOptions: [25, 50, 75],
                        filterRowStyle: {
                            position: "sticky",
                            top: 49,
                            background: "white",
                            zIndex: 5 /* optionally */
                        },
                        sorting: true,
                        pageSize: 25,
                        showTitle: false,
                        search: true,
                        doubleHorizontalScroll: true,
                        columnsButton: false,
                        draggable: true,
                        showFirstLastPageButtons: true,
                        toolbar: true,
                        padding: 'dense',
                        pagination: {
                            rowsPerPage: 'Rows per page:',
                            displayRows: 'off'
                        },
                        exportButton: { csv: true },
                        exportAllData: true,
                        rowStyle: rowData => ({
                            // check if 'hot order'
                            backgroundColor: rowData.shipmentFlagged
                                ? '#F8AFA7'
                                : null
                        }),
                        exportCsv: (columns, data) => prepareCsvExport(columns, data, this.state.organization, searchItem + 'Export_' + Date.now())

                    }}
                    onColumnDragged={handleColumnDrag}
                    onChangeColumnHidden={handleChangeColumnHidden}
                />
            </ThemeProvider>
        )
    }

    renderMap = () => {
        const { items } = this.state
        const { classes } = this.props

        // build a property we can use to look for duplicate locations
        const itemsLocMap = items.map(item => ({
            ...item,
            locCode:
                item.geoLocationOrigin +
                item.geoLocationDestination +
                item.geoLocationPortOfLoading +
                item.geoLocationPortOfDischarge
        }))
        // build unique location array to speed up map render (markers)
        const uniqueItems = itemsLocMap.reduce(function(p, c) {
            // if the next object's locCode is not found in the output array
            // push the object into the output array
            if (
                !p.some(function(el) {
                    return el.locCode === c.locCode
                })
            )
                p.push(c)
            return p
        }, [])

        return (
            <LoadScript
                id="script-loader"
                googleMapsApiKey="AIzaSyChJdyVnqwtrE_-7mUBGQNRUwa3oJFrJsE">
                <GoogleMap
                    className={classes.map}
                    id="InfoBox-example"
                    mapContainerStyle={mapContainerStyle}
                    zoom={1}
                    options={{ streetViewControl: false }}
                    onLoad={map => {
                        const bounds = new window.google.maps.LatLngBounds()

                        uniqueItems.map(item => {
                            if (
                                item.geoLocationOrigin !== '' &&
                                item.geoLocationDestination !== ''
                            ) {
                                const originCenter = getLatLng(
                                    item,
                                    'geoLocationOrigin'
                                )
                                const destinationCenter = getLatLng(
                                    item,
                                    'geoLocationDestination'
                                )
                                if (bounds && originCenter && destinationCenter) {
                                    bounds.extend(
                                        new window.google.maps.LatLng(
                                            originCenter.lat,
                                            originCenter.lng
                                        )
                                    )
                                    bounds.extend(
                                        new window.google.maps.LatLng(
                                            destinationCenter.lat,
                                            destinationCenter.lng
                                        )
                                    )
                                }
                            }

                            return true
                        })

                        if (bounds.isEmpty()) {
                            bounds.extend(
                                new window.google.maps.LatLng(
                                    42.2611071,
                                    -100.0855555
                                )
                            )
                            var listener = window.google.maps.event.addListener(
                                map,
                                'idle',
                                function() {
                                    if (map.getZoom() > 16) map.setZoom(3)
                                    window.google.maps.event.removeListener(
                                        listener
                                    )
                                }
                            )
                        }

                        map.fitBounds(bounds)
                    }}>
                    {uniqueItems.map(item => {
                        const originCenter = getLatLng(
                            item,
                            'geoLocationOrigin'
                        )
                        const destinationCenter = getLatLng(
                            item,
                            'geoLocationDestination'
                        )
                        const portOfLoadingCenter = getLatLng(
                            item,
                            'geoLocationPortOfLoading'
                        )
                        const portOfDischargeCenter = getLatLng(
                            item,
                            'geoLocationPortOfDischarge'
                        )

                        return renderMarkers(
                            item.shipmentNumber,
                            item,
                            null,
                            originCenter,
                            destinationCenter,
                            portOfLoadingCenter,
                            portOfDischargeCenter
                        )
                    })}
                </GoogleMap>
            </LoadScript>
        )
    }

    renderSummary = () => {
        const { classes } = this.props
        const {
            resultsBox1Value,
            resultsBox1Title,
            resultsBox2Value,
            resultsBox2Title,
            resultsBox3Value,
            resultsBox3Title,
            resultsBox4Value,
            resultsBox4Title,
            resultsBox5Value,
            resultsBox5Title,
            key,
            currentSummaryTile
        } = this.state

        const handleSummaryTileClick = (tileName, tileId) => {
            // these may have changed
            this.saveContainerFilterValuesToState(this.tableRef)
            var newContainerList = this.state.items.filter(
                i => i.status !== null && i.status.includes(tileName)
            )
            this.setState({
                containerList: newContainerList,
                currentSummaryTile: tileId
            })
            localforage.setItem(key + '.containerList', newContainerList)
            localforage.setItem(key + '.currentSummaryTile', tileId)
        }

        return (
            <div className="theme-card root">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-blue"
                                    title={resultsBox1Title}
                                    data={resultsBox1Value}
                                    isClicked={currentSummaryTile === 1}
                                    onClick={() => {
                                        this.setState({
                                            containerList: this.state.items,
                                            currentSummaryTile: 1
                                        })
                                        localforage.setItem(key + '.containerList', this.state.items)
                                        localforage.setItem(key + '.currentSummaryTile', 1)
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-dark-orchid"
                                    title={resultsBox2Title}
                                    data={resultsBox2Value}
                                    isClicked={currentSummaryTile === 2}
                                    onClick={() => {
                                        handleSummaryTileClick(resultsBox2Title, 2)
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-forest-green"
                                    title={resultsBox3Title}
                                    data={resultsBox3Value}
                                    isClicked={currentSummaryTile === 3}
                                    onClick={() => {
                                        handleSummaryTileClick(resultsBox3Title, 3)
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-ruby"
                                    title={resultsBox4Title}
                                    data={resultsBox4Value}
                                    isClicked={currentSummaryTile === 4}
                                    onClick={() => {
                                        handleSummaryTileClick(resultsBox4Title, 4)
                                    }}
                                />
                            </Grid>
                            {resultsBox5Title ? (
                                <Grid item xs>
                                    <SummaryInfo
                                        className="theme-mini-card gm-purple-heart"
                                        title={resultsBox5Title}
                                        data={resultsBox5Value}
                                        isClicked={currentSummaryTile === 5}
                                        onClick={() => {
                                            handleSummaryTileClick(resultsBox5Title, 5)
                                        }}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    render() {
        const { classes } = this.props

        const {
            clients,
            clientId,
            selectedDate,
            fromDate,
            toDate,
            minDate,
            todayDate,
            referenceType,
            dateView,
            itemReference,
            mode,
            organization,
            titleIcon,
            containerSearchTypes,
            primaryColor,
            themeTextColor,
            showContainerDialog,
            selectedContainer,
            dateFiltering
        } = this.state

        const currentClient = clients?.find(e => e.id === clientId)

        let contents = this.state.loading ? (
            <LinearProgress color="primary" />
        ) : (
            <div>
                <CardContent className="p-0">
                    <Paper className="paper page-cover-height-200px">
                        <Grid container spacing={0} direction="column">
                            <Grid
                                item
                                xs={12}
                                className="mb-0 mb-4 w-100 d-flex">
                                <Grid item xs={3}>
                                    {this.state.error && (
                                        <Grid item xs={12}>
                                            <Alert
                                                className="mb-4"
                                                onClose={() => {
                                                    this.setState({
                                                        error: false
                                                    })
                                                }}
                                                severity="error">
                                                {' '}
                                                Sorry, there was an error while
                                                retrieving items.
                                            </Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid
                                container
                                spacing={0}
                                className="mb-4 w-100 d-flex mobile-mode-no-flex">
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div
                                        className="mr-2 mb-3 map"
                                        style={{
                                            height: mapContainerStyle.height,
                                            position: 'relative',
                                            overflow: 'hidden'
                                        }}>
                                        {this.renderMap()}
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                    className="p-25 pb-0 mt-2">
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding">
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="select-label-client">
                                                    Client
                                                </InputLabel>
                                                <Select
                                                    labelId="select-label-client"
                                                    value={
                                                        clientId !== null
                                                            ? clients?.findIndex(
                                                                  c =>
                                                                      c.id ===
                                                                      clientId
                                                              ) === -1
                                                                ? clients[0]?.id
                                                                : clientId
                                                            : clients[0]?.id || ''
                                                    }
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'client'
                                                        )
                                                    }>
                                                    {clients?.map(function(
                                                        client,
                                                        i
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={
                                                                    client.id
                                                                }>
                                                                {client.name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl className="mr-auto">
                                                <div>
                                                    <InputLabel
                                                        id="select-label-mode"
                                                        className="my-auto">
                                                        Mode
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-mode"
                                                        id="mode"
                                                        value={mode}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'mode'
                                                            )
                                                        }>
                                                        <MenuItem value={'ALL'}>
                                                            All
                                                        </MenuItem>
                                                        <MenuItem value={'AIR'}>
                                                            Air
                                                        </MenuItem>
                                                        <MenuItem value={'SEA'}>
                                                            Sea
                                                        </MenuItem>
                                                        <MenuItem value={'ROA'}>
                                                            Road
                                                        </MenuItem>
                                                        <MenuItem value={'COU'}>
                                                            Courier
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="select-label-referencetype">
                                                    Search Type
                                                </InputLabel>
                                                <Select
                                                    labelId="select-label-referencetype"
                                                    id="reference-type"
                                                    value={referenceType || ''}
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'reference-type'
                                                        )
                                                    }>
                                                    {
                                                        containerSearchTypes.map(
                                                            function(
                                                                type,
                                                                i
                                                            ) {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            i+1
                                                                        }
                                                                        value={
                                                                            type.searchType
                                                                        }>
                                                                        {
                                                                            type.searchTypeDescription
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl
                                                className={classes.formControl}>
                                                <InputLabel id="Reference2">
                                                    Reference # (3 chars min)
                                                </InputLabel>
                                                <TextField
                                                    size="small"
                                                    id="reference"
                                                    labelid="Reference2"
                                                    placeholder="Reference # (3 chars min)"
                                                    value={itemReference || ''}
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'reference'
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding  ">
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20 mt-2">
                                            <FormControl className="mx-auto">
                                                <div>
                                                    <InputLabel
                                                        className="my-auto mx-auto flex-shrink-1"
                                                        id="select-label-dateview">
                                                        Date View
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-dateview"
                                                        id="dateView"
                                                        value={dateView}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'dateView'
                                                            )
                                                        }>
                                                        <MenuItem value={'ETD'}>
                                                            ETD
                                                        </MenuItem>
                                                        <MenuItem value={'ETA'}>
                                                            ETA
                                                        </MenuItem>
                                                        <MenuItem value={'ATA'}>
                                                            ATA
                                                        </MenuItem>
                                                        <MenuItem value={'ATD'}>
                                                            ATD
                                                        </MenuItem>
                                                        <MenuItem value={'GOO'}>
                                                            Gate Out Origin
                                                        </MenuItem>
                                                        <MenuItem value={'GIO'}>
                                                            Gate In Origin
                                                        </MenuItem>
                                                        <MenuItem value={'LoV'}>
                                                            Loaded on Vessel
                                                        </MenuItem>
                                                        <MenuItem value={'ULoV'}>
                                                            Unloaded on Vessel
                                                        </MenuItem>
                                                        <MenuItem value={'GOD'}>
                                                            Gate Out Destination
                                                        </MenuItem>
                                                        <MenuItem value={'ED'}>
                                                            Estimated Delivery
                                                        </MenuItem>
                                                        <MenuItem value={'Completed'}>
                                                            Delivery Completed
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20 mt-2">
                                            <FormControl className="mx-auto">
                                                <div>
                                                    <InputLabel id="select-label-date"> 
                                                        Date
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-date"
                                                        value={selectedDate}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'selectedDate'                                                            
                                                            )
                                                        }>
                                                        {dateFiltering.map(
                                                            function(date, i) { 
                                                                return (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={
                                                                            date.id
                                                                        }>
                                                                        {
                                                                            date.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="">
                                            <FormControl className="mx-auto calender-icon-controller">
                                                <div>
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disabled={selectedDate === 10 ? true : false}
                                                            autoOk
                                                            format={organization?.pickerDateFormat}
                                                            label="From Date"
                                                            value={
                                                                fromDate
                                                                    ? fromDate
                                                                    : minDate
                                                            }
                                                            onChange={e =>
                                                                this.handleChangeValue(
                                                                    e,
                                                                    'fromDate'
                                                                )
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="">
                                            <FormControl className="mx-auto  calender-icon-controller">
                                                <div>
                                                    <MuiPickersUtilsProvider
                                                        className={
                                                            classes.textField
                                                        }
                                                        utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disabled={selectedDate === 10 ? true : false}
                                                            autoOk
                                                            format={organization?.pickerDateFormat}
                                                            label="To Date"
                                                            value={
                                                                toDate
                                                                    ? toDate
                                                                    : todayDate
                                                            }
                                                            minDate={
                                                                fromDate
                                                                    ? fromDate
                                                                    : todayDate
                                                            }
                                                            onChange={e =>
                                                                this.handleChangeValue(
                                                                    e,
                                                                    'toDate'
                                                                )
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding mt-2 ">
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}>
                                            <Button
                                                className="MuiButton-containedPrimary submit w-100"
                                                style={{
                                                    background: primaryColor,
                                                    color: themeTextColor
                                                }}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                disabled={this.state.searching}
                                                onClick={e =>
                                                    this.handleSubmit(true)
                                                }>
                                                {this.state.searching
                                                    ? 'Loading...'
                                                    : 'QUICK SEARCH'}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}>
                                            <Button
                                                style={{
                                                    border: primaryColor,
                                                    color: primaryColor
                                                }}
                                                size="small"
                                                className="submit mb-2 w-100 secondary"
                                                variant="contained"
                                                color="secondary"
                                                onClick={this.handleClearAll}>
                                                RESET SEARCH
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className="mb-2 w-100 d-flex align-items-center mobile-mode-no-flex">
                                <Grid item xs={12} className="px-4 w-100">
                                    {this.renderSummary()}
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} className="w-100 px-3">
                                <div className="psa-shipment-table custom-table-styles w-100">
                                    <Grid
                                        container
                                        spacing={0}
                                        className="mb-4 w-100">
                                        <Grid item xs={12}>
                                            <div className="first-row-filter-hidden">
                                                {this.renderContainersTable(
                                                    this.state.containerList
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </CardContent>
                <DialogContainer
                    dialogCallback={this.setContainerDialogState}
                    showDialog={showContainerDialog}
                    container={selectedContainer}
                    organization={organization}
                    client={currentClient}
                />
            </div>
        )

        return <div>{contents}</div>
    }

    async populateClientsData() {
        const token = await authService.getAccessToken()
        const { clientId, key } = this.state
        const headers = {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }

        const response = await fetch('api/clients/getclientslist?module=containers', headers)

        if (response.ok) {
            const data = await response.json()
            this.setState({ clients: data }, () => {
                const { clients } = this.state
                const cachedClientId = localStorage.getItem(key + '.clientId')
                if (cachedClientId) {
                    // see if cached client id is in clients array
                    const client = clients?.find(
                        client => client.id === cachedClientId
                    )
                    if (client) {
                        // happy days
                    } else {
                        // set client id to first client in clients array
                        this.setState({ clientId: clients[0]?.id }, () => {
                            localStorage.setItem(key + '.clientId', clients[0]?.id)
                        })
                    }
                } else {
                    // set client id to first client in clients array
                    this.setState({ clientId: clients[0]?.id }, () => {
                        localStorage.setItem(key + '.clientId', clients[0]?.id)
                    })
                }
                localforage.setItem(key + '.clients', data)
            })
        } else {
            this.setState({ loading: false, error: true })
        }
    }

    async populateConfig() {
        const { key } = this.state
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({ 
            organization: data,
            primaryColor: data.colorThemePrimaryRGB,
            themeTextColor: data.colorThemeTextRGB,
            dateFiltering: data.dateFilterTypeArray
        })
        localforage.setItem(key + '.organization', data)
    }

    async fetchGridSearchTypes() {
        if (this.state.clientId) {
            const token = await authService.getAccessToken()
            const response = await fetch(
                'api/DataManagement/GetGridSearchTypes?clientId=' +
                    this.state.clientId +
                    '&module=CONTAINER',
                {
                    headers: !token ? {} : { Authorization: `Bearer ${token}` }
                }
            )
            if (response.ok) {
                const data = await response.json()
                this.setState({ containerSearchTypes: data })
            }
        }
    }

    getQueryString = (useQuickSearch = true, initialLoad = false) => {
        const {
            clientId,
            referenceType,
            fromDate,
            toDate,
            mode,
            itemReference,
            originUNLOCOCodeObject,
            originCountryCodeObject,
            originName,
            destinationUNLOCOCodeObject,
            destinationCountryCodeObject,
            destinationName,
            dateView,
            searchItem,
            selectedDate
        } = this.state

        const queryString = require('query-string')

        var qs = {}

        qs['SearchItem'] = searchItem
        qs['referenceType'] = referenceType
        qs['Mode'] = mode
        qs['UseQuickSearch'] = true

        if (itemReference !== null && itemReference.length > 1) {
            qs['ShipmentSearchNumber'] = itemReference
        }

        if (originUNLOCOCodeObject !== null) {
            qs['Origin'] = originUNLOCOCodeObject.unloco
        }

        if (originCountryCodeObject !== null) {
            qs['OriginCountryName'] = originCountryCodeObject.countryName
        }

        if (originName !== null) {
            qs['OriginName'] = originName
        }

        if (destinationUNLOCOCodeObject !== null) {
            qs['Destination'] = destinationUNLOCOCodeObject.unloco
        }

        if (destinationCountryCodeObject !== null) {
            qs['DestinationCountryName'] =
                destinationCountryCodeObject.countryName
        }

        if (destinationName !== null) {
            qs['DestinationName'] = destinationName
        }

        qs['DateType'] = dateView

        if (fromDate != null) {
            qs['FromDate'] = fromDate.toISOString(false)
        }

        if (toDate != null) {
            qs['ToDate'] = toDate.toISOString(false)
        }

        if (clientId !== null) {
            qs['ClientId'] = clientId
        }

        if (initialLoad) {
            qs['InitialLoad'] = true
        }

        qs['SelectedDate'] = selectedDate

        return queryString.stringify(qs)
    }

    async populateTableData(useQuickSearch = true, initialLoad = false) {
        const { clientId, key } = this.state
        this.setState({ searching: true })
        const token = await authService.getAccessToken()

        if (clientId !== null) {
            this.setState({ error: false })

            const headers = {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }

            const response = await fetch(
                `api/shipments/GetContainers?` +
                    this.getQueryString(useQuickSearch, initialLoad),
                headers
            )

            if (response.ok) {
                const data = await response.json()

                this.setState({
                    resultsBox1Value: data.resultsBox1Value,
                    resultsBox1Title: data.resultsBox1Title,
                    resultsBox2Value: data.resultsBox2Value,
                    resultsBox2Title: data.resultsBox2Title,
                    resultsBox3Value: data.resultsBox3Value,
                    resultsBox3Title: data.resultsBox3Title,
                    resultsBox4Value: data.resultsBox4Value,
                    resultsBox4Title: data.resultsBox4Title,
                    resultsBox5Value: data.resultsBox5Value,
                    resultsBox5Title: data.resultsBox5Title
                })

                localforage.setItem(key + '.containerList', data.containers)
                localforage.setItem(key + '.items', data.containers)
                localStorage.setItem(
                    key + '.resultsBox1Value',
                    data.resultsBox1Value
                )
                localStorage.setItem(
                    key + '.resultsBox1Title',
                    data.resultsBox1Title
                )
                localStorage.setItem(
                    key + '.resultsBox2Value',
                    data.resultsBox2Value
                )
                localStorage.setItem(
                    key + '.resultsBox2Title',
                    data.resultsBox2Title
                )
                localStorage.setItem(
                    key + '.resultsBox3Value',
                    data.resultsBox3Value
                )
                localStorage.setItem(
                    key + '.resultsBox3Title',
                    data.resultsBox3Title
                )
                localStorage.setItem(
                    key + '.resultsBox4Value',
                    data.resultsBox4Value
                )
                localStorage.setItem(
                    key + '.resultsBox4Title',
                    data.resultsBox4Title
                )
                localStorage.setItem(
                    key + '.resultsBox5Value',
                    data.resultsBox5Value
                )
                localStorage.setItem(
                    key + '.resultsBox5Title',
                    data.resultsBox5Title
                )

                this.setState({
                    items: data.containers,
                    containerList: data.containers,
                    loading: false
                })
            } else {
                this.setState({ loading: false, error: true })
            }
        } else {
            this.setState({ loading: false })
        }

        this.setState({ searching: false })
    }
}
export default withStyles(styles)(ContainerTable)
