import React, { Suspense, Component } from 'react'
import authService from './api-authorization/AuthorizeService'
import Alert from '@material-ui/lab/Alert'
import { Grid } from '@material-ui/core'
import { Card, CardMedia } from '@material-ui/core'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { LinearProgress, Typography } from '@material-ui/core'
import { renderMarkers, getLatLng } from '../components/shared/MapHelper'
import { renderTable } from '../components/shared/TableHelper'
import { withStyles } from '@material-ui/core/styles'
import CardTitle from './shared/CardTitle'
import { Paper } from '@material-ui/core'
import { Link } from '@material-ui/core'
import { CardContent } from '@material-ui/core'
import ShipmentInfoCard from './shared/ShipmentInfoCard'
import ShipmentDetailCard from './shared/ShipmentDetailCard'
import ShipmentContainersTable from './shared/ShipmentContainersTable'
import ShipmentPacklinesTable from './shared/ShipmentPacklinesTable'
import { downloadInvoicePDFForShipment } from '../api/api'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import DocumentUploadSelectDialog from './shared/DocumentUploadSelectDialog'
import TransactionCommentsCard from './shared/TransactionCommentsCard'
import TransactionCommentsHeaderCard from './shared/TransactionCommentsHeaderCard'
import { getOrgNameFromOrganization } from '../utils/organizations'
import { canSeeAccounting, canSeeOrders } from '../utils/permissions'

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    },
    bgColorSection: {
        backgroundColor: theme.bgColorSection
    }
})

// we need to lazy load due to speed of downloading docs
const ShipmentDocumentsCard = React.lazy(() =>
    import('./shared/ShipmentDocumentsCard')
)

const bgColorSection = 'bg-white text-dark'

const milestones = [
    {
        key: 'description',
        label: 'Description',
        icon: true
    },
    {
        key: 'estimatedDate',
        label: 'Estimate',
        icon: false
    },
    {
        key: 'actualDate',
        label: 'Actual',
        icon: false
    }
]

const referenceNumbers = [
    { key: 'numberType', label: 'Number Type', icon: false },
    { key: 'numberRef', label: 'Number', icon: false },
    { key: 'typeDescription', label: 'Type Description', icon: false }
]

const notes = [
    { key: 'title', label: 'Note Type', icon: false },
    { key: 'date', label: 'Date', icon: false },
    { key: 'note', label: 'Detail', icon: false }
]

const invoices = [
    { key: 'invoiceNumber', label: 'Invoice Number', icon: false, link: true },
    { key: 'description', label: 'Description', icon: false }
]

const routingInfo = [
    { key: 'vesselName', label: 'Vessel Name', icon: false },
    { key: 'masterBillNumber', label: 'Master Bill', icon: false },
    { key: 'legNumber', label: 'Leg #', icon: false },
    { key: 'legType', label: 'Leg Type', icon: false },
    { key: 'transportMode', label: 'Transport Mode', icon: false },
    { key: 'consolMode', label: 'Consol Mode', icon: false },
    { key: 'loadPort', label: 'Load Port', icon: false },
    { key: 'etd', label: 'ETD', icon: false },
    { key: 'atd', label: 'ATD', icon: false },
    { key: 'dischargePort', label: 'Discharge Port', icon: false },
    { key: 'eta', label: 'ETA', icon: false },
    { key: 'ata', label: 'ATA', icon: false },
    { key: 'voyageFlight', label: 'Voyage Flight', icon: false },
    { key: 'vesselLloydsIMO', label: 'IMO Number', icon: false }
]

class ShipmentDetailPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            clientId: props.match.params.clientId,
            shipmentId: props.match.params.shipmentId,
            containerId: props.match.params.containerId,
            item: null,
            itemextra: null,
            loading: true,
            error: false,
            errorMessage: '',
            dialogOpen: false,
            selectedContainer: {},
            organization: null,
            expanded: 'panel1',
            uri: props.uri || 'GetShipment',
            client: null,
            showInvoices: false,
            showOrders: false
        }
        this.showDialog = this.showDialog.bind(this)

        this.populateShipmentData = this.populateShipmentData.bind(this)
        this.fetchAllShipmentData = this.fetchAllShipmentData.bind(this)
        this.fetchExtraShipmentData = this.fetchExtraShipmentData.bind(this)
        this.handleACCChange = this.handleACCChange.bind(this)
    }

    async componentDidMount() {
        await this.populateShipmentData()
    }

    getTransactionTypeByURI(uri) {  
        switch (uri) {
            case 'GetShipment':
                return 'shipment'
            case 'GetCustom':
                return 'customs'
            case 'GetBooking':
                return 'booking'
        }
        return 'shipment'
    }

    showDialog(container) {
        const { item, itemextra } = this.state

        this.setState({
            dialogOpen: true,
            selectedContainer: container
        })

        this.setState(prevState => {
            let selectedContainer = Object.assign(
                {},
                prevState.selectedContainer
            )
            selectedContainer['geoLocationOrigin'] = item.geoLocationOrigin
            selectedContainer['geoLocationDestination'] =
                item.geoLocationDestination
            selectedContainer['geoLocationPortOfLoading'] =
                item.geoLocationPortOfLoading
            selectedContainer['geoLocationPortOfDischarge'] =
                item.geoLocationPortOfDischarge
            selectedContainer['geoLocationCurrentLocation'] =
                itemextra.geoLocationCurrentLocation

            return { selectedContainer }
        })

        this.setState(prevState => {
            let selectedContainer = Object.assign(
                {},
                prevState.selectedContainer
            )
            selectedContainer['mode'] = item.mode

            return { selectedContainer }
        })
    }

    handleActionsDialog = action => {
        if (action) {
            this.handleDelete()
        }

        this.setState({
            dialogOpen: !this.state.dialogOpen
        })
    }

    handleACCChange = panel => (event, expanded) => {
        this.setState({ expanded: expanded ? panel : false })
    }

    goBack = () => {
        localStorage.setItem('referredFromDetailPage', JSON.stringify(true))
        this.props.history.goBack()
    }

    renderInvoiceLink = (i, index, arrayKey) => {
        const { item, clientId, showInvoices } = this.state

        const handleDownloadInvoicePDF = async (invoiceNumber, clientId) => {
            downloadInvoicePDFForShipment(
                invoiceNumber,
                clientId,
                (blob, headers) => {
                    const filename = headers
                        .get('Content-Disposition')
                        .split('filename=')[1]
                        .split(';')[0]

                    const newFilename = filename.slice(1, filename.length - 1)

                    const url = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', newFilename)
                    document.body.appendChild(link)
                    link.click()
                    link.parentNode.removeChild(link)
                },
                () => {}
            )
        }

        return (
            <div>
                { showInvoices && i[arrayKey.key] ? (
                    <Link
                        component="button"
                        className="btn btn-link"
                        variant="body2"
                        style={{ color: 'blue' }}
                        onClick={() => {
                            handleDownloadInvoicePDF(i[arrayKey.key], clientId)
                        }}>
                        {' '}
                        {i[arrayKey.key] || '-'}
                    </Link>
                ) : (
                    i[arrayKey.key] || '-'
                )}
            </div>
        )
    }

    renderMilestones = () => {
        const { itemextra } = this.state
        const { classes } = this.props

        return (
            <CardTitle title="Milestones" checkOn={false} disablePadding={true}>
                {
                    itemextra?.milestones ? (
                        renderTable(
                            itemextra?.milestones ?? [],
                            milestones,
                            'milestones',
                            classes,
                            null,
                            {
                                maxHeight: '200px',
                            }
                        )) : (
                            <Alert severity="info" className="w-100">
                                No records found.
                            </Alert>
                        ) 
                }
            </CardTitle>
        )
    }

    renderReferenceNumbers = () => {
        const { itemextra } = this.state
        const { classes } = this.props

        return (
            <CardTitle
                title="Reference Numbers"
                checkOn={false}
                disablePadding={true}>
                {renderTable(
                    itemextra?.referenceNumbers ?? [],
                    referenceNumbers,
                    'referenceNumbers',
                    classes,
                    null,
                    {
                        maxHeight: '200px'
                    }
                )}
            </CardTitle>
        )
    }

    renderNotes = () => {
        const { itemextra } = this.state
        const { classes } = this.props

        return (
            <CardTitle title="Notes" checkOn={false} disablePadding={true}>
                {renderTable(
                    itemextra?.notes ?? [],
                    notes, 'notes', 
                    classes,
                    null,
                    {
                        maxHeight: '200px'
                    }
                )}
            </CardTitle>
        )
    }

    renderInvoices = () => {
        const { item } = this.state
        const { classes } = this.props

        if (item === null) {
            return null
        }

        return (
            <CardTitle title="Invoices" checkOn={false} disablePadding={true}>
                {renderTable(
                    item?.invoices ?? [],
                    invoices,
                    'invoices',
                    classes,
                    this.renderInvoiceLink,
                    {
                        maxHeight: '200px'
                    }
                )}
            </CardTitle>
        )
    }

    renderRoutingInfo = () => {
        const { itemextra } = this.state
        const { classes } = this.props

        return (
            <CardTitle title="Routing" checkOn={false} disablePadding={true}>
                {renderTable(
                    itemextra?.routingInfo ?? [],
                    routingInfo,
                    'routing',
                    classes,
                    null,
                    {
                        maxHeight: '200px'
                    }
                )}
            </CardTitle>
        )
    }

    renderMarker = () => {
        const { item, itemextra } = this.state

        const originCenter = getLatLng(item, 'geoLocationOrigin')
        const destinationCenter = getLatLng(item, 'geoLocationDestination')
        const portOfLoadingCenter = getLatLng(item, 'geoLocationPortOfLoading')
        const portOfDischargeCenter = getLatLng(
            item,
            'geoLocationPortOfDischarge'
        )
        const currentLocationCenter = getLatLng(
            itemextra,
            'geoLocationCurrentLocation'
        )

        return renderMarkers(
            item.shipmentNumber,
            item,
            itemextra,
            originCenter,
            destinationCenter,
            portOfLoadingCenter,
            portOfDischargeCenter,
            currentLocationCenter
        )
    }

    renderPickupInfo = () => {
        const { itemextra, organization } = this.state
        return (
            <div className="p-0 pl-3 w-100">
                <h5 class="text-Curious-blue my-2 font-14">Pickup Info</h5>

                <Grid container direction="row" spacing={1}>
                    <Grid item xs={4} className="sm-flex-100">
                        <div className="column">
                            <span class="font-12 font-raleway-medium">
                                Addresss
                            </span>
                            <br />
                            <span class="mb-0 font-14  font-nutosans-bold text-black">
                                {(itemextra !== null &&
                                    itemextra.pickupInfo?.name) ||
                                    ''}
                            </span>
                            <br />
                            <span class="mb-0 font-14  font-nutosans-bold text-black">
                                {(itemextra !== null &&
                                    itemextra.pickupInfo?.addresLine1) ||
                                    ''}
                            </span>
                            <br />
                            <span class="mb-0 font-14  font-nutosans-bold text-black">
                                {(itemextra !== null &&
                                    itemextra.pickupInfo?.city) ||
                                    ''}
                                ,{' '}
                                {(itemextra !== null &&
                                    itemextra.pickupInfo?.state) ||
                                    ''}
                                ,{' '}
                                {(itemextra !== null &&
                                    itemextra.pickupInfo?.postalCode) ||
                                    ''}
                            </span>
                            <br />
                            <span class="mb-0 font-14  font-nutosans-bold text-black">
                                {(itemextra !== null &&
                                    itemextra.pickupInfo?.country) ||
                                    ''}
                            </span>
                        </div>
                    </Grid>
                    <Grid
                        item
                        lg={8}
                        sm={8}
                        xs={12}
                        hidden={
                            organization?.shipmentServiceSelected == 'NORMANHK'
                        }>
                        <Grid container direction="row" spacing={1}>
                            {
                                itemextra?.pickupInfo?.hidePickupRequiredBy === false &&
                                    <Grid item xs={4} className="sm-flex-100">
                                        <div className="">
                                            <span class="font-12 font-raleway-medium">
                                                Pickup Required By
                                            </span>
                                            <br />
                                            <span class="mb-0 font-14  font-nutosans-bold text-black">
                                                {itemextra.pickupInfo?.pickupRequiredBy || '-'}
                                            </span>
                                        </div>
                                    </Grid>
                            }
                            <Grid item xs={4} className="sm-flex-100">
                                <div className="">
                                    <span class="font-12 font-raleway-medium">
                                        Estimated Pickup
                                    </span>
                                    <br />
                                    <span class="mb-0 font-14  font-nutosans-bold text-black">
                                        {(itemextra !== null &&
                                            itemextra.pickupInfo
                                                ?.estimatedPickup) ||
                                            '-'}
                                    </span>
                                    <br />
                                </div>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <div className="">
                                    <span class="font-12 font-raleway-medium">
                                        Actual Pickup
                                    </span>
                                    <br />
                                    <span class="mb-0 font-14  font-nutosans-bold text-black">
                                        {(itemextra !== null &&
                                            itemextra.pickupInfo
                                                ?.actualPickup) ||
                                            '-'}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderDeliveryInfo = () => {
        const { itemextra, organization } = this.state
        return (
            <div className="p-0 pl-3 w-100">
                <h5 class="text-Curious-blue my-2 font-14">Delivery Info</h5>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={4} lg={4}>
                        <div>
                            <span className="font-12 font-raleway-medium">
                                Address
                            </span>
                            <br />
                            <span class="mb-0 font-14  font-nutosans-bold text-black">
                                {(itemextra !== null &&
                                    itemextra.deliveryInfo?.name) ||
                                    ''}
                            </span>
                            <br />
                            {
                                itemextra?.deliveryInfo?.shortCode &&
                                <>
                                    <span class="mb-0 font-14  font-nutosans-bold text-black">
                                        {itemextra.deliveryInfo.shortCode}
                                    </span>
                                    <br />
                                </>
                            }
                            <span class="mb-0 font-14  font-nutosans-bold text-black">
                                {(itemextra !== null &&
                                    itemextra.deliveryInfo?.addresLine1) ||
                                    ''}
                            </span>
                            <br />
                            <span class="mb-0 font-14  font-nutosans-bold text-black">
                                {(itemextra !== null &&
                                    itemextra.deliveryInfo?.city) ||
                                    ''}
                                ,{' '}
                                {(itemextra !== null &&
                                    itemextra.deliveryInfo?.state) ||
                                    ''}
                                ,{' '}
                                {(itemextra !== null &&
                                    itemextra.deliveryInfo?.postalCode) ||
                                    ''}
                            </span>
                            <br />
                            <span class="mb-0 font-14  font-nutosans-bold text-black">
                                {(itemextra !== null &&
                                    itemextra.deliveryInfo?.country) ||
                                    ''}
                            </span>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        lg={8}
                        hidden={
                            organization?.shipmentServiceSelected == 'NORMANHK'
                        }>
                        <Grid container direction="row" spacing={1}>
                            {
                                itemextra?.deliveryInfo?.hideDeliveryRequiredBy === false &&
                                    <Grid item xs={4} className="sm-flex-100">
                                        <div className="">
                                            <span className="font-12 font-raleway-medium">
                                                Delivery Required By
                                            </span>
                                            <br />
                                            <span class="mb-0 font-14  font-nutosans-bold text-black">
                                                {itemextra.deliveryInfo?.deliveryRequiredBy || '-'}
                                            </span>
                                        </div>
                                    </Grid>
                            }
                            <Grid item xs={4} className="sm-flex-100">
                                <div className="">
                                    <div className="">
                                        <span className="font-12 font-raleway-medium">
                                            Estimated Delivery
                                        </span>
                                        <br />
                                        <span class="mb-0 font-14  font-nutosans-bold text-black">
                                            {(itemextra !== null &&
                                                itemextra.deliveryInfo
                                                    ?.estimatedDelivery) ||
                                                '-'}
                                        </span>
                                        <br />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <div className="">
                                    <span className="font-12 font-raleway-medium">
                                        Actual Delivery
                                    </span>
                                    <br />
                                    <span class="mb-0 font-14  font-nutosans-bold text-black">
                                        {(itemextra !== null &&
                                            itemextra.deliveryInfo
                                                ?.actualDelivery) ||
                                            '-'}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderMap = () => {
        const { item, itemextra, organization } = this.state
        const { classes } = this.props

        if (getOrgNameFromOrganization(organization) === 'VISY') {
            if (itemextra.trackingMapUrl) {
                return (
                    <iframe
                        src={itemextra.trackingMapUrl}
                        id="p44Map"
                        name="p44Map"
                        height="700"
                        width="100%"
                        loading="lazy"
                        allowFullScreen
                    >
                    </iframe>
                )
            }
        }

        // default map
        return (
            <CardMedia>
                <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyChJdyVnqwtrE_-7mUBGQNRUwa3oJFrJsE">
                    <GoogleMap
                        id="infobox-example"
                        mapContainerStyle={{
                            position: 'relative',
                            width: '100%',
                            height: '550px'
                        }}
                        zoom={2}
                        options={{
                            streetViewControl: false
                        }}
                        onLoad={map => {
                            const bounds = new window.google.maps.LatLngBounds()
                            const originCenter = getLatLng(
                                item,
                                'geoLocationOrigin'
                            )
                            const destinationCenter = getLatLng(
                                item,
                                'geoLocationDestination'
                            )
                            if (bounds && originCenter && destinationCenter) {
                                bounds.extend(
                                    new window.google.maps.LatLng(
                                        originCenter.lat,
                                        originCenter.lng
                                    )
                                )
                                bounds.extend(
                                    new window.google.maps.LatLng(
                                        destinationCenter.lat,
                                        destinationCenter.lng
                                    )
                                )
                                map.fitBounds(bounds)
                            }
                        }}>
                        {item !== null && this.renderMarker()}
                    </GoogleMap>
                </LoadScript>
            </CardMedia>
        )
    }

    render() {
        const {
            loading,
            error,
            errorMessage,
            item,
            itemextra,
            dialogOpen,
            organization,
            expanded,
            selectedContainer,
            client,
            uri,
            showOrders
        } = this.state

        let contents = loading ? (
            <LinearProgress color="primary" />
        ) : (
            <>
                {error && (
                    <>
                        <Alert
                            variant="filled"
                            onClose={() => {
                                this.setState({ error: false })
                            }}
                            severity="warning">
                            {errorMessage}
                        </Alert>
                        <br />
                    </>
                )}
                <Grid container spacing={3} direction="row">
                    <div class="w-100 details-info-page">
                        <CardContent className="p-2">
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                className="d-flex align-items-stretch mobile-col-direction-reverse">
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    className="pb-3 ">
                                    <span
                                        className="text-Curious-blue font-18 font-raleway-bold"
                                        style={{ cursor: 'pointer' }}
                                        onClick={this.goBack}>
                                        {' '}
                                        <ArrowBackIcon />
                                        Go Back...
                                    </span>
                                </Grid>
                            </Grid>
                            <div class="bg-white mb-20 ">
                                <Paper className="paper p-0">
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="row"
                                        className="d-flex align-items-stretch mobile-col-direction-reverse">
                                        <Grid
                                            item
                                            lg={6}
                                            sm={12}
                                            xs={12}
                                            className="pb-3 ">
                                            <div>
                                                {item != null && (
                                                    <ShipmentInfoCard
                                                        item={item}
                                                        itemextra={itemextra}
                                                        showFirstRow={true}
                                                        etd={item.etd}
                                                        eta={item.eta}
                                                        atd={item.atd}
                                                        ata={item.ata}
                                                        shipper={item.shipper}
                                                        consignee={item.consignee}
                                                        origin={item.originCity}
                                                        destination={
                                                            item.destinationCity
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div>
                                                {item != null && (
                                                    <ShipmentDetailCard
                                                        item={item}
                                                        itemextra={itemextra}
                                                        shippersRef={
                                                            getOrgNameFromOrganization(organization) === 'HAVI'
                                                                ? itemextra?.customizedFields?.find(
                                                                    c =>
                                                                        c.key ===
                                                                        'CustomerPO'
                                                                )?.value
                                                                : item.shippersReferenceNumber
                                                        }
                                                        ownerRef={item.ownerRef}
                                                        orderRef={
                                                            organization?.shipmentServiceSelected ==
                                                            'NORMANHK'
                                                                ? item.orderReferenceNumber
                                                                : item.orderRef
                                                        }
                                                        showOrders={showOrders}
                                                        quantity={item.quantity}
                                                        volumeLabel="Volume"
                                                        volume={item.volume}
                                                        weight={item.weight}
                                                        shippersRefLabel={
                                                            organization?.shipmentServiceSelected ==
                                                            'NORMANHK'
                                                                ? 'Booking Ref'
                                                                : getOrgNameFromOrganization(organization) === 'HAVI' ? 'Customer PO #' : "Shipper's Ref"
                                                        }
                                                        organization={organization}
                                                        clientId={
                                                            this.state.clientId
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </Grid>

                                        <Grid
                                            item
                                            lg={6}
                                            sm={12}
                                            xs={12}
                                            className="d-flex align-items-stretch">
                                            <Card
                                                className={[
                                                    bgColorSection,
                                                    'w-100'
                                                ]}>
                                                {this.renderMap()}
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div class="bg-white mb-20">
                                <Paper className="paper p-0">
                                    <Grid container spacing={0} direction="row">
                                        <Grid
                                            item
                                            lg={6}
                                            sm={6}
                                            xs={12}
                                            className="">
                                            {this.renderPickupInfo()}
                                        </Grid>
                                        <Grid
                                            item
                                            lg={6}
                                            sm={6}
                                            xs={12}
                                            className="">
                                            {this.renderDeliveryInfo()}
                                        </Grid>
                                    </Grid>
                                    <div class="separator bg-primary mt-2"></div>
                                    {itemextra && itemextra.hideRoutingInfo === false && (
                                        <>
                                            <Grid container spacing={0} direction="row">
                                                <Grid item xs={12}>
                                                    <div className="custom-psa-accordian">
                                                        <div className="px-3 sm-px-0">
                                                            <ExpansionPanel
                                                                square
                                                                expanded={
                                                                    expanded === 'panel1'
                                                                }
                                                                onChange={this.handleACCChange(
                                                                    'panel1'
                                                                )}>
                                                                <ExpansionPanelSummary
                                                                    expandIcon={
                                                                        <ExpandMoreIcon />
                                                                    }
                                                                    className="p-0">
                                                                    <Typography>
                                                                        <h4 className="text-Curious-blue font-14">
                                                                            Routing
                                                                        </h4>
                                                                    </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    <Typography>
                                                                        <div className="psa-shipment-table  psa-shipment-table-info">
                                                                            {this.renderRoutingInfo()}
                                                                        </div>
                                                                    </Typography>
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <div class="separator bg-primary"></div>
                                        </>
                                    )}
                                    <Grid container spacing={1} direction="row">
                                        <div className="custom-psa-accordian w-100 mx-3 sm-mx-0">
                                            <ExpansionPanel
                                                square
                                                expanded={expanded === 'panel1'}
                                                onChange={this.handleACCChange(
                                                    'panel1'
                                                )}>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>
                                                        <h4 className="text-Curious-blue font-14">
                                                            Milestones, Notes & Comments
                                                        </h4>
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Typography>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="p-0">
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                className="d-flex align-items-stretch px-0 mobile-no-dflex">
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    className="sm-w-100">
                                                                    <div class="font-12 text-black mb-2 ">
                                                                        Milestones
                                                                    </div>
                                                                    <div className="psa-shipment-table  psa-shipment-table-info  min-max-height-200px custom-scrollbar">
                                                                        {this.renderMilestones()}
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    className="sm-w-100">
                                                                    <div class="font-12 text-black mb-2">
                                                                        Notes
                                                                    </div>
                                                                    <div className="psa-shipment-table  psa-shipment-table-info min-max-height-200px w-100">
                                                                        {this.renderNotes()}
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    className="sm-w-100">
                                                                    <div class="font-12 text-black mb-2">
                                                                        Comments
                                                                        <TransactionCommentsHeaderCard
                                                                            clientId={
                                                                                this
                                                                                    .state
                                                                                    .clientId
                                                                            }
                                                                            transactiontype={this.getTransactionTypeByURI(uri)}
                                                                            transactionnumber={
                                                                                item?.shipmentNumber
                                                                            }
                                                                            shipmentnotes={itemextra?.notes}>
                                                                        </TransactionCommentsHeaderCard>
                                                                    </div>
                                                                    <div className="psa-shipment-table  psa-shipment-table-info min-max-height-200px w-100">
                                                                        <TransactionCommentsCard
                                                                            transactionComments={itemextra?.comments}>
                                                                        </TransactionCommentsCard>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                    </Grid>
                                    <div class="separator bg-primary"></div>
                                    <Grid container spacing={1} direction="row">
                                        <div className="custom-psa-accordian w-100 mx-3 sm-mx-0">
                                            <ExpansionPanel
                                                square
                                                expanded={expanded === 'panel1'}
                                                onChange={this.handleACCChange(
                                                    'panel1'
                                                )}>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>
                                                        <h4 className="text-Curious-blue font-14">
                                                            Documents, Reference
                                                            Numbers & Invoices
                                                        </h4>
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Typography>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                className="d-flex align-items-stretch mobile-no-dflex ">
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="sm-w-100">
                                                                    <div class="font-12 text-black mb-2">
                                                                        Documents
                                                                        <DocumentUploadSelectDialog
                                                                            clientId={
                                                                                this
                                                                                    .state
                                                                                    .clientId
                                                                            }
                                                                            transactiontype={this.getTransactionTypeByURI(uri)}
                                                                            transactionnumber={
                                                                                item?.shipmentNumber
                                                                            }
                                                                            buyercode=""></DocumentUploadSelectDialog>
                                                                    </div>
                                                                    <div className="psa-shipment-table documents-table  psa-shipment-table-info  min-max-height-200px">
                                                                        <Suspense
                                                                            fallback={
                                                                                null
                                                                            }>
                                                                            <ShipmentDocumentsCard
                                                                                shipmentId={
                                                                                    this
                                                                                        .state
                                                                                        .shipmentId
                                                                                }
                                                                                clientId={
                                                                                    this
                                                                                        .state
                                                                                        .clientId
                                                                                }
                                                                                classes={
                                                                                    this
                                                                                        .props
                                                                                        .classes
                                                                                }
                                                                                itemuri={
                                                                                    this
                                                                                        .state
                                                                                        .uri
                                                                                }
                                                                            />
                                                                        </Suspense>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={3}
                                                                    className="sm-w-100">
                                                                    <div class="font-12 text-black mb-2">
                                                                        Reference
                                                                        Numbers
                                                                    </div>
                                                                    <div className="psa-shipment-table  psa-shipment-table-info min-max-height-200px w-100">
                                                                        {this.renderReferenceNumbers()}
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={3}
                                                                    className="sm-w-100">
                                                                    <div class="font-12 text-black mb-2">
                                                                        Invoices
                                                                    </div>
                                                                    <div className="psa-shipment-table  psa-shipment-table-info  min-max-height-200px">
                                                                        {this.renderInvoices()}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                    </Grid>
                                    <div class="separator bg-primary"></div>
                                    <Grid container spacing={1} direction="row">
                                        <div className="custom-psa-accordian w-100 mx-3 sm-mx-0">
                                            <ExpansionPanel
                                                square
                                                expanded={expanded === 'panel1'}
                                                onChange={this.handleACCChange(
                                                    'panel1'
                                                )}>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>
                                                        <h4 className="text-Curious-blue font-14">
                                                            Pack Lines{' '}
                                                        </h4>
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Typography>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                className="d-flex align-items-stretch no-mobile-dflex">
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className="sm-w-100">
                                                                    <div className="psa-shipment-table  psa-shipment-table-info min-max-height-200px">
                                                                        <ShipmentPacklinesTable
                                                                            item={
                                                                                itemextra
                                                                            }
                                                                            organization={
                                                                                organization
                                                                            }
                                                                            isReview={
                                                                                false
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                    </Grid>
                                    <div class="separator bg-primary"></div>

                                    <Grid container spacing={1} direction="row">
                                        <div className="custom-psa-accordian w-100 mx-3 sm-mx-0">
                                            <ExpansionPanel
                                                square
                                                expanded={expanded === 'panel1'}
                                                onChange={this.handleACCChange(
                                                    'panel1'
                                                )}>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>
                                                        <h4 className="text-Curious-blue font-14">
                                                            Containers
                                                        </h4>
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Typography>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                className="d-flex align-items-stretch">
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className="">
                                                                    <div className="psa-shipment-table  psa-shipment-table-info mx-3 min-max-height-200px">
                                                                        <ShipmentContainersTable
                                                                            handleActionsDialog={
                                                                                this
                                                                                    .handleActionsDialog
                                                                            }
                                                                            showDialog={
                                                                                this
                                                                                    .showDialog
                                                                            }
                                                                            dialogOpen={
                                                                                dialogOpen
                                                                            }
                                                                            selectedContainer={
                                                                                selectedContainer
                                                                            }
                                                                            organization={
                                                                                organization
                                                                            }
                                                                            item={
                                                                                itemextra
                                                                            }
                                                                            isReview={
                                                                                false
                                                                            }
                                                                            client={client}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                    </Grid>
                                </Paper>
                            </div>
                        </CardContent>
                    </div>
                </Grid>
            </>
        )

        return <div>{contents}</div>
    }

    async populateShipmentData() {
        this.fetchAllShipmentData()
            .then(([shipment, orgConfig, clientData]) => {
                canSeeAccounting(orgConfig).then((showInvoices) => {
                    this.setState({ showInvoices: showInvoices })
                })
                canSeeOrders(orgConfig).then((showOrders) => {
                    this.setState({ showOrders: showOrders })
                })
                this.setState({
                    item: shipment,
                    organization: orgConfig,
                    client: clientData
                }, () => {
                    this.fetchExtraShipmentData()
                        .then(([shipmentExtra]) => {
                            this.setState({
                                itemextra: shipmentExtra,
                                loading: false,
                            }, () => {
                                // if there is a containerid set set need to open the container dialog
                                const containerId = this.state.containerId
                                if (containerId) {
                                    this.setState({
                                        selectedContainer: shipmentExtra.containers.find(
                                            c => c.containerNumber === containerId)
                                    }, () => {
                                        this.showDialog(this.state.selectedContainer)
                                    })
                                }
                            }
                            )
                        })
                        .catch(error => {
                            this.setState({ 
                                loading: false, 
                                error: true,
                                errorMessage: 'Unable to fetch shipment details from Cargowise One. Please try again later.'
                             })
                        })
                })
            })
            .catch(error => {
                this.setState({ 
                    loading: false, 
                    error: true,
                    errorMessage: 'Unable to fetch shipment details. Please try again later.'
                })
            })

    }

    async fetchAllShipmentData() {
        const { shipmentId, clientId, uri } = this.state
        const queryString = require('query-string')

        const qs = {
            ClientId: clientId,
            ShipmentSearchNumber: shipmentId
        }

        const token = await authService.getAccessToken()

        const [
            shipmentResponse,
            orgConfigResponse,
            clientDataResponse
        ] = await Promise.all([
            fetch(`api/shipments/${uri}?` + queryString.stringify(qs), {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }),
            fetch('api' + ApplicationPaths.AppInfo + '?clientId=' + clientId),
            fetch(`api/clients/` + clientId, {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            })
        ])

        const shipment = await shipmentResponse.json()
        const orgConfig = await orgConfigResponse.json()
        const clientData = await clientDataResponse.json()

        return [shipment, orgConfig, clientData]
    }

    async fetchExtraShipmentData() {
        const { shipmentId, clientId, uri } = this.state
        const queryString = require('query-string')

        const qs = {
            ClientId: clientId,
            ShipmentSearchNumber: shipmentId
        }

        const token = await authService.getAccessToken()

        const [
            shipmentExtraResponse,
        ] = await Promise.all([
            fetch(`api/shipments/${uri}extra?` + queryString.stringify(qs), {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            })
        ])

        const shipmentExtra = await shipmentExtraResponse.json()

        return [shipmentExtra]
    }
}
export default withStyles(styles, { withTheme: true })(ShipmentDetailPage)
