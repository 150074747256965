import React, { Component } from 'react'
import OrderDetailPage from './OrderDetailPage'

class OrderDetails extends Component {
    render() {
        let contents = <OrderDetailPage uri="GetOrder" {...this.props} />

        return <div>{contents}</div>
    }
}
export default OrderDetails
