const dataTypesKey = 'shipment.create.dataTypes'

export const getDataMapping = () => {
    return JSON.parse(sessionStorage.getItem(dataTypesKey))
}

export const setDataMapping = data => {
    sessionStorage.setItem(dataTypesKey, data)
}

export const deleteDataMapping = data => {
    sessionStorage.removeItem(dataTypesKey)
}
