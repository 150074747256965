import React, { Component } from 'react'
import authService from './api-authorization/AuthorizeService'
import {
    Card,
    CardContent,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import { Grid, FormControl } from '@material-ui/core'
import { Button, TextField, InputAdornment } from '@material-ui/core'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import Typography from '@material-ui/core/Typography'
import { emailValidation } from '../components/shared/Helpers'
import Alert from '@material-ui/lab/Alert'
import CardTitle from './shared/CardTitle'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'

import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const classes = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    }
}))
const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textFieldForm: {
        width: theme.textFieldForm.width
    }
})

const adminUsersPath = { pathname: `${ApplicationPaths.AdminUsers}` }
const usrRole = 'Administrator'

class AdminUserDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userId:
                props.match.params.userId !== null &&
                props.match.params.userId.toLowerCase() !== 'new'
                    ? props.match.params.userId
                    : null,
            actions: {
                isLoading: this.userId,
                isPosting: false
            },
            reportAccessList: [],
            dashboardAccessList: [],
            emailSubscriptionList: [],
            displayNoReports: false,
            displayNoDashboards: false,
            user: [],
            organization: null,
            requiredFields: [],
            isValidForm: false,
            isValidUserFirstName: true,
            errorTextUserFirstName: '',
            isValidUserLastName: true,
            errorTextUserLastName: '',
            isValidUserEmail: true,
            errorTextUserEmail: '',
            alert: {
                open: false,
                success: false,
                message: ''
            },
            searchShipment: true,
            expanded: 'panel1'
        }
        this.onSearch = this.onSearch.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleACCChange = this.handleACCChange.bind(this)
    }

    componentDidMount() {
        const { userId } = this.state
        this.populateConfig()
        if (userId) {
            this.GetAdminUserData(userId)
            this.getAdminUserReportsData(userId)
            this.getAdminUserDashboardsData(userId)
        } else {
            this.GetAdminEmailSubscriptionList()
            this.getAdminUserReportsData('new')
            this.getAdminUserDashboardsData('new')
        }
    }

    componentWillUnmount() {
        if (this._asyncRequest) {
            this._asyncRequest.cancel()
        }
    }

    updateUserField = (value, key) => {
        this.setState(prevState => {
            let user = Object.assign({}, prevState.user)
            user[key] = value
            return { user }
        })
    }

    onSearch(e) {
        this.handleChange(e, 'searchShipment', false)
    }

    handleChange(event, field, isRequired) {
        this.setState(prevState => {
            let alerts = Object.assign({}, prevState.alerts)
            alerts.showSuccess = false
            alerts.showFail = false
            return { alerts }
        })

        let value = event.target.value
        let isValidField = true
        let isValidForm = true
        let msgError = ''

        if (value.length === 0 && isRequired) {
            isValidField = false
            isValidForm = false
            msgError = 'cannot be empty.'
        }

        switch (field) {
            case 'user-first-name':
                this.setState({
                    errorTextUserFirstName: isValidField
                        ? ''
                        : 'First Name ' + msgError,
                    isValidUserFirstName: isValidField
                })
                this.updateUserField(value, 'firstName')
                break

            case 'user-last-name':
                this.setState({
                    errorTextUserLastName: isValidField
                        ? ''
                        : 'Last Name ' + msgError,
                    isValidUserLastName: isValidField
                })
                this.updateUserField(value, 'lastName')
                break

            case 'user-email':
                var emailValid = emailValidation(value)

                if (!emailValid) {
                    isValidField = false
                    isValidForm = false
                    msgError = 'Email address is not valid'
                }

                this.setState({
                    errorTextEmail: msgError,
                    isValidUserEmail: isValidField
                })

                this.updateUserField(value, 'email')
                break

            case 'searchShipment':
                const searchShipment = this.state.searchShipment
                this.setState({ searchShipment: !searchShipment })
                break

            default:
                break
        }

        this.setState({ isValidForm: isValidForm })
    }

    handleACCChange = panel => (event, expanded) => {
        this.setState({ expanded: expanded ? panel : false })
    }
    handleSubmit = async event => {
        const { userId, user, isValidForm, emailSubscriptionList } = this.state
        event.preventDefault()

        if (isValidForm) {
            let isNew = false

            let data = {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                emailSubscriptionsArray: emailSubscriptionList
            }

            if (userId && user.id) {
                data['id'] = user.id
            } else {
                isNew = true
            }

            this.saveAdminUserData(data, isNew)
        }
    }

    handleCancel(event) {
        window.location.href = adminUsersPath.pathname
    }

    handleCloseAlert() {
        this.setState(prevState => {
            let alert = Object.assign({}, prevState.alert)
            alert.open = false
            alert.success = false
            alert.message = ''
            return { alert }
        })
    }

    handleReportChange = (key, value) => {
        const { reportAccessList } = this.state
        const reports = reportAccessList.map(report => {
            if (report.reportId === key) {
                report['isChecked'] = value
            }
            return report
        })
        this.setState({
            reportAccessList: reports,
            isValidForm: true
        })
    }

    handleEmailSubscriptionChage = (key, value) => {
        const { emailSubscriptionList } = this.state
        const subscriptions = emailSubscriptionList.map(subscription => {
            if (subscription.subscriptionId === key) {
                subscription['subscribed'] = value
            }
            return subscription
        })

        this.setState({
            emailSubscriptionList: subscriptions,
            isValidForm: true
        })
    }

    handleDashboardChange = (key, value) => {
        const { dashboardAccessList } = this.state
        const dashboards = dashboardAccessList.map(dashboard => {
            if (dashboard.dashboardId === key) {
                dashboard['isChecked'] = value
            }
            return dashboard
        })
        this.setState({
            dashboardAccessList: dashboards,
            isValidForm: true
        })
    }
    async populateConfig() {
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({ organization: data })
    }

    renderInput = (
        id,
        label,
        placeholder,
        orgValue,
        required,
        adornment,
        valid = null,
        errorText = null
    ) => {
        const { user } = this.state
        const { classes } = this.props
        var params = {}
        if (valid !== null && errorText !== null) {
            params['helperText'] = !this.state[valid]
                ? this.state[errorText]
                : ''
            params['error'] = !this.state[valid]
        }

        return (
            <Grid item xs={12} className="d-flex flex-shrink-1">
                <FormControl>
                    <TextField
                        id={id}
                        className={classes.textFieldForm}
                        required={required}
                        label={label}
                        placeholder={placeholder}
                        value={user[orgValue] || ''}
                        InputProps={
                            adornment
                                ? {
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <RecentActorsIcon />
                                          </InputAdornment>
                                      )
                                  }
                                : null
                        }
                        onChange={e => this.handleChange(e, id, required)}
                        {...params}
                    />
                </FormControl>
            </Grid>
        )
    }

    renderAlert = () => {
        const alert = this.state.alert
        return alert.open ? (
            <div className="w-100">
                <Alert
                    variant="filled"
                    severity={alert.success ? 'success' : 'error'}
                    onClose={() => {
                        this.handleCloseAlert()
                    }}>
                    {alert.message}
                </Alert>
            </div>
        ) : null
    }

    render() {
        const { isValidForm, actions, searchShipment, expanded } = this.state
        const {
            reportAccessList,
            dashboardAccessList,
            emailSubscriptionList,
            displayNoDashboards,
            displayNoReports,
            organization
        } = this.state
        const primarycolor =
            organization !== null && organization.colorThemePrimaryRGB !== null
                ? `${organization.colorThemePrimaryRGB}`
                : '#ffffff'
        const secodarycolor =
            organization !== null &&
            organization.colorThemeSecondaryRGB !== null
                ? `${organization.colorThemeSecondaryRGB}`
                : '#000000'
        const teritorycolor =
            organization !== null && organization.colorThemeTertiaryRGB !== null
                ? `${organization.colorThemeTertiaryRGB}`
                : '#ffffff'
        const themeRGB =
            organization !== null && organization.colorThemeRGB !== null
                ? `${organization.colorThemeRGB}`
                : '#000000'
        const themeTextColor =
            organization !== null && organization.colorThemeTextRGB !== null
                ? `${organization.colorThemeTextRGB}`
                : '#000000'

        return (
            <div>
                <CardContent class="p-0 psa-shipment-paper-card">
                    {this.renderAlert()}
                    <Paper className={classes.paper} class="psashipmentpaper">
                        <form
                            onSubmit={this.handleSubmit}
                            noValidate
                            autoComplete="off">
                            <div className="accordian-body-padding">
                                <Grid container spacing={1}>
                                    <Grid
                                        container
                                        xs={12}
                                        className="mb-0"
                                        spacing={0}
                                        class="mb-4 w-100">
                                        <Grid
                                            item
                                            xs={12}
                                            direction="column"
                                            spacing={4}>
                                            <div className="no-p-0 custom-psa-accordian custom-MuiCardContent-root">
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel1'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel1'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                User Details
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'user-first-name',
                                                                        'First Name',
                                                                        'First Name',
                                                                        'firstName',
                                                                        true,
                                                                        true,
                                                                        'isValidUserFirstName',
                                                                        'errorTextUserFirstName'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'user-last-name',
                                                                        'Last Name',
                                                                        'Last Name',
                                                                        'lastName',
                                                                        true,
                                                                        false,
                                                                        'isValidUserLastName',
                                                                        'errorTextUserLastName'
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    {this.renderInput(
                                                                        'user-email',
                                                                        'Email',
                                                                        'Email',
                                                                        'email',
                                                                        true,
                                                                        false,
                                                                        'isValidUserEmail',
                                                                        'errorTextUserEmail'
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel19'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel19'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                User Role
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={4}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={4}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="xxl-2">
                                                                    <div class="MuiGrid-root d-flex flex-shrink-1 MuiGrid-item MuiGrid-grid-xs-12">
                                                                        <div class="MuiFormControl-root">
                                                                            <div class="MuiFormControl-root MuiTextField-root AdminUserDetails-textFieldForm-1536">
                                                                                <label
                                                                                    class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated Mui-required Mui-required"
                                                                                    data-shrink="false"
                                                                                    for="user-email"
                                                                                    id="user-email-label">
                                                                                    User
                                                                                    Role
                                                                                </label>
                                                                                <div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                                                                                    <div class="field-disabled">
                                                                                        {
                                                                                            usrRole
                                                                                        }
                                                                                    </div>{' '}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel2'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel2'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                checkOn={false}
                                                                style={{
                                                                    color: primarycolor
                                                                }}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                Module Access
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={0}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={12}>
                                                                    <CardTitle
                                                                        checkOn={
                                                                            true
                                                                        }
                                                                        itemLabel="Search Shipment"
                                                                        disabledOn={
                                                                            true
                                                                        }
                                                                        searchOn={
                                                                            searchShipment
                                                                        }
                                                                        callback={this.onSearch.bind(
                                                                            this
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel3'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel3'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                checkOn={false}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                Report Access
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={2}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={12}>
                                                                    {displayNoReports && (
                                                                        <div>
                                                                            No
                                                                            matching
                                                                            data
                                                                            found
                                                                        </div>
                                                                    )}
                                                                    {reportAccessList.map(
                                                                        report => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        onChange={event => {
                                                                                            this.handleReportChange(
                                                                                                report.reportId,
                                                                                                event
                                                                                                    .target
                                                                                                    .checked
                                                                                            )
                                                                                        }}
                                                                                        color="default"
                                                                                        name=""
                                                                                        checked={
                                                                                            report.isChecked
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    report.reportName
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel4'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel4'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                checkOn={false}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                Dashboard Access
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={2}
                                                                className="mb-4">
                                                                {displayNoDashboards && (
                                                                    <div>
                                                                        No
                                                                        matching
                                                                        data
                                                                        found
                                                                    </div>
                                                                )}
                                                                {dashboardAccessList.map(
                                                                    dashboard => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    onChange={event => {
                                                                                        this.handleDashboardChange(
                                                                                            dashboard.dashboardId,
                                                                                            event
                                                                                                .target
                                                                                                .checked
                                                                                        )
                                                                                    }}
                                                                                    color="default"
                                                                                    name=""
                                                                                    checked={
                                                                                        dashboard.isChecked
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={
                                                                                dashboard.dashboardName
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator"
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded === 'panel5'
                                                    }
                                                    onChange={this.handleACCChange(
                                                        'panel5'
                                                    )}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={{
                                                                    border: primarycolor,
                                                                    color: primarycolor
                                                                }}
                                                            />
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.heading
                                                            }
                                                            class="accordian-card-heading">
                                                            <h5
                                                                style={{
                                                                    color: primarycolor
                                                                }}
                                                                checkOn={false}>
                                                                <CardTitle
                                                                    title=""
                                                                    class="p-0"></CardTitle>{' '}
                                                                Email Subscriptions
                                                            </h5>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                space={2}
                                                                spacing={2}
                                                                className="mb-4">
                                                                <Grid
                                                                    item
                                                                    xl={12}>
                                                                    {emailSubscriptionList.map(
                                                                        subscription => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        onChange={event => {
                                                                                            this.handleEmailSubscriptionChage(
                                                                                                subscription.subscriptionId,
                                                                                                event
                                                                                                    .target
                                                                                                    .checked
                                                                                            )
                                                                                        }}
                                                                                        color="default"
                                                                                        name=""
                                                                                        checked={
                                                                                            subscription.subscribed
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    subscription.subscriptionName
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                                <div
                                                    class="separator "
                                                    style={{
                                                        background: primarycolor
                                                    }}></div>
                                                <div class="d-flex justify-content-end w-100 my-4">
                                                    <Grid item class="mr-3">
                                                        <Button
                                                            style={{
                                                                border: primarycolor,
                                                                color: primarycolor
                                                            }}
                                                            size="medium"
                                                            color="secondary"
                                                            variant="contained"
                                                            onClick={e =>
                                                                this.handleCancel()
                                                            }
                                                            disabled={
                                                                actions.isPosting
                                                            }>
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            style={{
                                                                background: primarycolor,
                                                                color: themeTextColor
                                                            }}
                                                            type="submit"
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={e =>
                                                                this.handleSubmit(
                                                                    e
                                                                )
                                                            }
                                                            disabled={
                                                                !isValidForm ||
                                                                actions.isPosting ||
                                                                actions.isLoading
                                                            }>
                                                            Save
                                                        </Button>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Paper>
                </CardContent>
            </div>
        )
    }

    async GetAdminUserData(id) {
        const token = await authService.getAccessToken()
        const response = await fetch('api/users/' + id, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            const data = await response.json()
            this.setState({ 
                user: data,
                emailSubscriptionList: data.emailSubscriptionsArray ? data.emailSubscriptionsArray : [],
            })
        }
        this.setState(prevState => {
            let actions = Object.assign({}, prevState.actions)
            actions.isLoading = false
            return { actions }
        })
    }

    async saveAdminUserData(data, isNew) {
        this.setState({ isPosting: true })
        let errText = ''
        const token = await authService.getAccessToken()
        let url = isNew
            ? 'api/users/CreateAdministrator'
            : 'api/users/AdminPutUser/' + data.id
        let _method = isNew ? 'POST' : 'PUT'
        await await fetch(url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                let results = null

                if (response.ok) {
                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = true
                        alert.message = 'User information is saved.'
                        return { alert }
                    })

                    this.setState(prevState => {
                        let actions = Object.assign({}, prevState.actions)
                        actions.isPosting = false
                        return { actions }
                    })

                    results = response.json()
                } else {
                    if (response.status === 409) {
                        errText = 'User ID already exists.'
                    } else {
                        errText = 'Code[' + response.status + ']'
                    }
                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })

                    results = response.text()
                }
                return results
            })

            .then(d => {
                if (d.id) {
                    this.setState({ user: d, userId: data.id })
                    this.saveAdminUserReportsData()
                    this.saveAdminUserDashboardsData(isNew)
                } else {
                    this.setState({ user: data })
                }
            })
            .catch(error => {
                this.setState(prevState => {
                    let actions = Object.assign({}, prevState.actions)
                    actions.isPosting = false
                    return { actions }
                })

                this.setState({ isValidForm: false })

                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                console.log(error)
            })
    }

    async GetAdminEmailSubscriptionList() {
        const token = await authService.getAccessToken()
        const response = await fetch('api/users/GetAdminEmailSubscriptionList', {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            const data = await response.json()
            this.setState({ emailSubscriptionList: data })
        }
    }

    //to get the organizations->reports
    async getAdminUserReportsData(id) {
        const token = await authService.getAccessToken()
        const response = await fetch('api/users/GetAdminUserBIReports/' + id, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data == null) {
                    data = []
                }
                this.setState({
                    reportAccessList: data
                })

                if (data.length == 0) {
                    this.setState({
                        displayNoReports: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    reportAccessList: []
                })
                this.setState({
                    displayNoReports: true,
                    isPosting: false
                })
            })

        this.setState({ isLoading: false })
    }

    //to get the organizations->dashboards
    async getAdminUserDashboardsData(id) {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/users/GetAdminUserBIDashboards/' + id,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
            .then(response => response.json())
            .then(data => {
                if (data == null) {
                    data = []
                }
                this.setState({
                    dashboardAccessList: data
                })
                if (data.length == 0) {
                    this.setState({
                        displayNoDashboards: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    dashboardAccessList: []
                })
                this.setState({
                    displayNoDashboards: true,
                    isPosting: false
                })
            })

        this.setState({ isLoading: false })
    }

    //to save the org->reports
    async saveAdminUserReportsData() {
        const token = await authService.getAccessToken()
        const { reportAccessList } = this.state
        const { userId } = this.state
        let list = []
        reportAccessList.map(x => {
            if (x.isChecked) {
                list.push({
                    UserId: userId,
                    ClientId: null,
                    PSAOrganizationId: null,
                    ReportID: x.reportId,
                    ReportName: x.reportName
                })
            }
        })
        let data = {
            UserId: userId,
            ClientId: null,
            Reports: list
        }
        let errText = ''
        await fetch('api/users/SaveUserBIReports', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                let results = null
                if (response.ok) {
                    // good
                } else {
                    errText = 'Code[' + response.status + ']'

                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                    results = response.text()
                }
            })

            .then(d => {
                this.setState({
                    isPosting: false
                })
            })
            .catch(error => {
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                this.setState({
                    isPosting: false
                })
            })
    }
    //to save the org->dashboards
    async saveAdminUserDashboardsData(isNew) {
        const token = await authService.getAccessToken()
        const { dashboardAccessList } = this.state
        const { userId } = this.state
        let list = []
        dashboardAccessList.map(x => {
            if (x.isChecked) {
                list.push({
                    UserId: userId,
                    ClientId: null,
                    PSAOrganizationId: null,
                    DashboardID: x.dashboardId,
                    DashboardName: x.dashboardName
                })
            }
        })
        let data = {
            UserId: userId,
            ClientId: null,
            Dashboards: list
        }
        let errText = ''
        await fetch('api/users/SaveUserBIDashboards', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                let results = null
                if (response.ok) {
                    // good
                } else {
                    errText = 'Code[' + response.status + ']'

                    this.setState(prevState => {
                        let alert = Object.assign({}, prevState.alert)
                        alert.open = true
                        alert.success = false
                        alert.message =
                            'Sorry, there was an error while saving these details.' +
                            errText
                        return { alert }
                    })
                    results = response.text()
                }
            })

            .then(d => {
                this.setState({
                    isPosting: false
                })
                if (isNew) {
                    //Go bak to the List
                    window.location.href = adminUsersPath.pathname
                }
            })
            .catch(error => {
                this.setState(prevState => {
                    let alert = Object.assign({}, prevState.alert)
                    alert.open = true
                    alert.success = false
                    alert.message =
                        'Sorry, there was an error while saving these details.'
                    return { alert }
                })
                this.setState({
                    isPosting: false
                })
            })
    }
}
export default withStyles(styles)(AdminUserDetails)
